import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaSessionAllIssues } from 'src/apps/wfa/services/WfaDashboardService';
import { TWfaSessionAllIssuesResult } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { WFA_SESSION_ALL_ISSUES } from 'src/common/providers/reactQueryProvider/QueryKeys';
import { ITableQueryParams } from 'src/common/types/TableQueryTypes';
import { IUseTableQueryParams } from 'src/common/types/UseTableQueryParamsType';

interface IUseWfaSessionAllIssuesQueryParams extends IUseTableQueryParams {
	sessionId: string;
}

export const useWfaSessionAllIssuesQuery = ({
	sessionId,
	filters,
	sort,
	page,
	pageSize
}: IUseWfaSessionAllIssuesQueryParams): UseQueryResult<TWfaSessionAllIssuesResult> => {
	const issuesQuery = useQuery(
		[WFA_SESSION_ALL_ISSUES, sessionId, filters, sort, page, pageSize],
		async (): Promise<TWfaSessionAllIssuesResult> =>
			getWfaSessionAllIssues({
				sessionId,
				filterOptions: filters as ITableQueryParams['filterOptions'],
				sort,
				page,
				pageSize
			}),
		{
			keepPreviousData: true
		}
	);

	return issuesQuery;
};
