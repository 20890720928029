// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-error {
  justify-content: center;
  display: flex;
  padding-top: 100px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/common/components/errors/full-page-error/FullPageError.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;AACD","sourcesContent":[".full-page-error {\n\tjustify-content: center;\n\tdisplay: flex;\n\tpadding-top: 100px;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
