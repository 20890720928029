import { IWfaSessionDetails } from '../../../WfaDashboardServiceTypes';
import { WfaSessionDetailsQuery } from '../WfaSessionDetails.generated';

export const transformSessionDetailsResult = (
	rawResults: WfaSessionDetailsQuery
): IWfaSessionDetails => {
	const session = rawResults?.wfa_session_runs?.[0];
	return {
		sessionId: session?.run_id,
		name: session?.session_name,
		runTime: session?.run_time,
		lastModifiedTime: session?.update_time,
		domain: session?.domain,
		createdBy: session?.user_name,
		detectionTypes: session?.detection_types,
		focusArea: session?.analysis_root_selector
	};
};
