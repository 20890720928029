// This file was copied from site scanner. Planning to move it to ui-common.
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvSelect,
	EvSpinner,
	OptionType,
	TITLE_MODES
} from '@evinced-private/ui-common';

import { asyncHandler } from 'src/common/helpers/AsyncHelper';
import {
	callCreateIssue,
	getOrganizations,
	getProjectFields,
	getProjects,
	getProjectTypesFromServer
} from 'src/common/services/api/AzureApi';
import { Logger } from 'src/common/services/Logger';
import { screenshotService } from 'src/common/services/ScreenshotService';
import { AZURE_FIELDS, IAzureFieldType, IAzureProject } from 'src/common/types/AzureTypes';
import { Dictionary } from 'src/common/types/LodashTypes';
import { Report } from 'src/common/types/ReportModel';

import { PlatformPopup } from '../platform-popup/PlatformPopup';

import { getBaseUrl, replaceProjectName } from './Helpers/AzureHelper';
import { IssueFormField } from './issueFields';

import './AzureIssueCreator.scss';

const alertPopupTitle = 'Connect to Azure';
const issueFormTitle = 'Create Issue in Azure';

const fieldsToDisplay = [
	'Work Item',
	'Title',
	'Reason',
	'Priority',
	'Description',
	'Attachment',
	'Assigned To',
	'Area Path',
	'Iteration Path'
];
const fieldsToIgnore = ['Iteration ID', 'Area ID'];

interface IAzureIssueCreatorProps {
	isOpen: boolean;
	closeModal: () => void;
	ticketData: Report;
}

export const AzureIssueCreator: FC<IAzureIssueCreatorProps> = ({
	closeModal,
	isOpen,
	ticketData
}: IAzureIssueCreatorProps): JSX.Element => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [host, setHost] = useState<string>(null);
	const [alertModalOpen, setAlertModalOpen] = useState(false);
	const [projects, setProjects] = useState<IAzureProject[]>([]);
	const [issueTypes, setIssueTypes] = useState<OptionType[]>([]);
	const [selectedProject, setSelectedProject] = useState<OptionType[]>([]);
	const [selectedIssueType, setSelectedIssueType] = useState<OptionType>(null);
	const [fields, setFields] = useState<Dictionary<IAzureFieldType>>(null);
	const [screenshotUrl, setScreenshotUrl] = useState<string>('');

	const updateField = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(newValue: any, field: IAzureFieldType, currentFields?: Dictionary<IAzureFieldType>): void => {
			const updatedField =
				typeof newValue === 'object'
					? { ...field, value: newValue.value }
					: { ...field, value: newValue };

			if (currentFields) {
				setFields({ ...currentFields, [field.referenceName]: updatedField });
			} else {
				setFields({ ...fields, [field.referenceName]: updatedField });
			}
		},
		[fields]
	);

	const updateIssueData = async (fields: Dictionary<IAzureFieldType>): Promise<void> => {
		try {
			if (ticketData) {
				const boundingBoxes = ticketData.elements.map((element) => element.boundingBox);
				const [screenshotImageData] = await asyncHandler(
					screenshotService.getFullPageScreenshotWithIssues({
						pageScreenshotUrl: ticketData.pageScreenshot,
						boundingBoxes
					})
				);

				if (ticketData.summary) {
					updateField(ticketData.summary, fields[AZURE_FIELDS.TITLE], fields);
				}
				if (ticketData.description) {
					updateField(ticketData.description, fields[AZURE_FIELDS.DESCRIPTION], fields);
				}
				if (screenshotImageData) {
					setScreenshotUrl(screenshotImageData as string);
				}
			}
		} catch (error) {
			Logger.error(
				`Error getting information regarding updateIssueData with error: ${error} with host: ${host}`
			);
		}
	};

	const checkIfLoggedIn = async (): Promise<void> => {
		try {
			const formattedOrganizations: OptionType[] = await getOrganizations();

			if (formattedOrganizations.length) {
				setAlertModalOpen(false);

				const organizationUrl = `${getBaseUrl()}/${formattedOrganizations[0].label}`;
				const formattedProjects: IAzureProject[] = await getProjects(organizationUrl);
				const formattedSelectedProject =
					formattedProjects?.length > 1
						? formattedProjects.filter((project) => project.name === formattedProjects[0].label)[0]
						: formattedProjects[0];
				const formattedHost = `${organizationUrl}/${formattedSelectedProject.label}`;

				setProjects(formattedProjects);
				setSelectedProject([formattedSelectedProject]);
				setHost(formattedHost);
			} else {
				setAlertModalOpen(true);
			}
		} catch (error) {
			Logger.error(
				`Error getting information regarding fetchData with error: ${error} with host: ${host}`
			);
		}
	};

	const fetchData = async (): Promise<void> => {
		try {
			if (host) {
				const issueTypes: OptionType[] = await getProjectTypesFromServer(host);
				const formattedProjects: IAzureProject[] = await getProjects(host);
				const formattedFields: Dictionary<IAzureFieldType> = await getProjectFields(
					host,
					issueTypes[0]?.label
				);

				setFields(formattedFields);
				setIssueTypes(issueTypes);
				setProjects(formattedProjects);
				setSelectedIssueType(issueTypes[0]);
				updateIssueData(formattedFields);
			}
		} catch (error) {
			Logger.error(
				`Error getting information regarding fetchData with error: ${error} with host: ${host}`
			);
		} finally {
			setLoading(false);
		}
	};

	const initApi = useCallback(async (): Promise<void> => {
		if (host) {
			try {
				setLoading(true);
				await fetchData();
			} catch (error) {
				Logger.error(
					`Error getting information regarding initApi with error: ${error} with host: ${host}`
				);
			}
		}
	}, [host]);

	useEffect(() => {
		checkIfLoggedIn();
	}, []);

	useEffect(() => {
		initApi();
	}, [initApi]);

	const onChangeProjectPicker = async (newProject: OptionType): Promise<void> => {
		const updatedUrl = replaceProjectName(host, newProject.label);
		try {
			setHost(updatedUrl);
			setLoading(true);
			setSelectedProject([newProject]);
			updateIssueData(fields);
		} catch (error) {
			Logger.error(
				`Error getting information regarding onChangeProjectPicker with error: ${error} with host: ${updatedUrl}`
			);
		} finally {
			setLoading(false);
		}
	};

	const onChangeIssueTypePicker = async (newIssueType: OptionType): Promise<void> => {
		setLoading(true);
		try {
			const formattedFields: Dictionary<IAzureFieldType> = await getProjectFields(
				host,
				newIssueType.label
			);

			setSelectedIssueType(newIssueType);
			setFields(formattedFields);
			updateIssueData(formattedFields);
		} catch (error) {
			Logger.error(
				`Error getting information regarding onChangeIssueTypePicker with error: ${error} with host: ${host}`
			);
		} finally {
			setLoading(false);
		}
	};

	const renderProjectPicker = useCallback((): JSX.Element => {
		return (
			<div className="form-element">
				<label className="new-form-label" htmlFor="project">
					Project <span className="require-sign">*</span>
				</label>
				<div className="form-input">
					<EvSelect
						id="project"
						options={projects}
						onChange={onChangeProjectPicker}
						isSearchable
						value={selectedProject}
						placeholder="Select project"
					/>
				</div>
			</div>
		);
	}, [projects, selectedProject]);

	const renderIssueTypePicker = useCallback((): JSX.Element => {
		return (
			<div className="form-element">
				<label className="new-form-label" htmlFor="issuetype">
					Issue Type <span className="require-sign">*</span>
				</label>
				<div className="form-input">
					<EvSelect
						id="issuetype"
						options={issueTypes}
						onChange={onChangeIssueTypePicker}
						isSearchable
						value={[selectedIssueType]}
						placeholder="Select project"
					/>
				</div>
			</div>
		);
	}, [selectedProject, selectedIssueType, issueTypes]);

	// publish button should be disabled as long as one of the required fields is not full
	const isPublishButtonDisabled = (): boolean => {
		if (fields) {
			const isDisabled = Object.keys(fields).some((fieldKey) => {
				const field = fields[fieldKey];

				// so the button should be disabled in such case
				if (field.alwaysRequired && !field.value && !fieldsToIgnore.includes(field.name)) {
					return true;
				}
				return field.alwaysRequired ? !fields[fieldKey] : false;
			});
			return isDisabled;
		}
		return true;
	};

	const createIssue = async (): Promise<void> => {
		try {
			setLoading(true);
			const shouldCloseModal = await callCreateIssue(
				host,
				selectedIssueType.label,
				screenshotUrl,
				fields
			);

			if (shouldCloseModal) {
				closeModal();
			}
		} catch (error) {
			Logger.error(
				`Error getting information regarding createIssue with error: ${error} with host: ${host}`
			);
		} finally {
			setLoading(false);
		}
	};

	const renderActionButtons = (): JSX.Element => {
		return (
			<>
				<EvButton
					className="cancel-button"
					onClick={closeModal}
					type={BUTTON_TYPES.SECONDARY}
					title="Cancel"
				>
					Cancel
				</EvButton>
				<EvButton
					disabled={isPublishButtonDisabled()}
					type={BUTTON_TYPES.PRIMARY}
					title="Create"
					onClick={createIssue}
				>
					Create
				</EvButton>
			</>
		);
	};

	const renderDynamicForm = useCallback((): JSX.Element => {
		return (
			<>
				{Object.entries(fields).map(([, field]) => {
					return fieldsToDisplay.includes(field.name) ||
						(field.alwaysRequired && !fieldsToIgnore.includes(field.name)) ? (
						<IssueFormField
							key={field.name}
							field={field}
							onChange={(value) => updateField(value, field)}
						/>
					) : null;
				})}
			</>
		);
	}, [fields]);

	const renderAttachment = (): JSX.Element => {
		return screenshotUrl ? (
			<div className="form-element" key="system.Attachment">
				<label className="new-form-label">Attachment</label>
				<div className="form-input">
					<div className="azure-issue-attachments">
						<div className="attachments-screenshot">
							<img alt="screenshot" src={screenshotUrl} />
						</div>
					</div>
				</div>
			</div>
		) : null;
	};

	const buttons = [
		{
			title: 'OK',
			onClick: () => {
				Logger.info('Error popup closed on OK click');
				closeModal();
			}
		}
	];

	if (!host && alertModalOpen) {
		return (
			<PlatformPopup
				title={alertPopupTitle}
				titleMode={TITLE_MODES.MAIN}
				buttons={buttons}
				isControlled
				isCentered
				isOpen={alertModalOpen}
				className="error-popup"
				onClose={closeModal}
				popupWidth="100%"
			>
				<div className="alert-popup">Azure is not connected</div>
			</PlatformPopup>
		);
	}

	return (
		<PlatformPopup
			title={issueFormTitle}
			isOpen={isOpen}
			popupWidth="100%"
			className="azure-popup"
			isBgTransparent
			titleMode={TITLE_MODES.MAIN}
			isControlled
			onClose={closeModal}
		>
			{!isLoading && isOpen ? (
				<div className="azure-issue-creator">
					<div className="azure-issue-content">
						<div className="azure-issue-form">
							<div className="azure-form-fields ">
								{renderProjectPicker()}
								{renderIssueTypePicker()}
								{renderDynamicForm()}
								{renderAttachment()}
							</div>
						</div>
					</div>
					<div className="azure-issue-action-buttons">{renderActionButtons()}</div>
				</div>
			) : (
				<div className="azure-issue-creator">
					<div className="spinner-wrapper">
						<EvSpinner />
					</div>
				</div>
			)}
		</PlatformPopup>
	);
};
