import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WEB_SDK_ISSUES } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { IUseTableQueryParams } from '../../../common/types/UseTableQueryParamsType';
import { getWebSdkTestIssues } from '../services/WebSdkDashboardService';
import { TWebSdkTestIssuesResult } from '../types/WebSdkDashboardServiceTypes';

interface IUseWebSdkIssuesQueryParams extends IUseTableQueryParams {
	runId: string;
}

export const useWebSdkIssuesQuery = ({
	runId,
	filters,
	sort,
	page,
	pageSize
}: IUseWebSdkIssuesQueryParams): UseQueryResult<TWebSdkTestIssuesResult> =>
	useQuery(
		[WEB_SDK_ISSUES, runId, filters, sort, page, pageSize],
		async (): Promise<TWebSdkTestIssuesResult> =>
			getWebSdkTestIssues({
				runId,
				filterOptions: filters,
				sort,
				page,
				pageSize
			}),
		{
			keepPreviousData: true
		}
	);
