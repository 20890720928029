import React from 'react';

import CloudStatusIcon from 'src/common/components/icons/CloudStatusIcon.svg';

import {
	WFA_NO_SYNCED_SESSIONS_MSG,
	WFA_NO_SYNCED_SESSIONS_TITLE
} from '../../../../common/pages/dashboards-container/errorMessages';

import { WfaError } from './WfaError';

interface WfaErrorProps {
	isNoData?: boolean;
}
export const WfaSessionsPageError: React.FC<WfaErrorProps> = ({ isNoData }) => {
	if (isNoData) {
		return (
			<WfaError
				mainTitle={WFA_NO_SYNCED_SESSIONS_TITLE}
				secondaryMsg={WFA_NO_SYNCED_SESSIONS_MSG}
				icon={CloudStatusIcon}
			/>
		);
	}

	return <WfaError icon={CloudStatusIcon} />;
};
