// hook code taken from here:
// https://usehooks-typescript.com/react-hook/use-interval

import { useEffect, useRef } from 'react';

/**
 * setInterval wrapper hook
 * @param callback - function to run
 * @param delay - interval in milliseconds or null to stop
 */
export const useInterval = (callback: () => void, delay: number | null): void => {
	const savedCallback = useRef<() => void | null>();
	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	});
	// Set up the interval.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		function tick() {
			if (typeof savedCallback?.current !== 'undefined') {
				// eslint-disable-next-line no-unused-expressions
				savedCallback?.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};
