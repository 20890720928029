import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaSessionIssuesFilterOptions } from 'src/apps/wfa/services/WfaDashboardService';
import { TWfaSessionIssuesFilterOptions } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { WFA_SESSION_FILTER_OPTIONS } from 'src/common/providers/reactQueryProvider/QueryKeys';

export const useWfaSessionFilterOptionsQuery = (
	sessionId: string
): UseQueryResult<TWfaSessionIssuesFilterOptions> => {
	const sessionDetailsQuery = useQuery([WFA_SESSION_FILTER_OPTIONS, sessionId], () =>
		getWfaSessionIssuesFilterOptions(sessionId)
	);

	return sessionDetailsQuery;
};
