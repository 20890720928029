import React from 'react';

import { ContactUsButton } from 'src/common/components/contact-us/ContactUsButton';
import ErrorLoadPageIcon from 'src/common/components/icons/ErrorLoadPageIcon.svg';
import { ProductType } from 'src/common/interfaces/Tenant';

import {
	DASHBOARD_ERROR_MSG,
	DASHBOARD_ERROR_TITLE
} from '../../../../common/pages/dashboards-container/errorMessages';
import { LoadErrorMsgLayout } from '../../../../common/pages/property/LoadErrorMsgLayout';

import './WebSdkError.scss';

interface WebSDKErrorProps {
	mainTitle?: string;
	secondaryMsg?: string;
	button?: React.ReactElement;
	icon?: SVGElement;
	hideButton?: boolean;
}
export const WebSdkError: React.FC<WebSDKErrorProps> = ({
	button,
	mainTitle = DASHBOARD_ERROR_TITLE,
	secondaryMsg = DASHBOARD_ERROR_MSG,
	icon = ErrorLoadPageIcon,
	hideButton
}) => {
	return (
		<LoadErrorMsgLayout
			mainTitle={mainTitle}
			secondaryMsg={secondaryMsg}
			icon={icon}
			className="web-sdk-dashboard-error-msg"
		>
			{!hideButton ? button || <ContactUsButton product={ProductType.WEB_SDK} /> : null}
		</LoadErrorMsgLayout>
	);
};
