import React, { FC } from 'react';

import { ProductType } from 'src/common/interfaces/Tenant';
import { DashboardContainer } from 'src/common/pages/dashboards-container/DashboardContainer';

import { MSdkDashboardTestsPage } from '../sessions/MSdkDashboardSessionsPage';

export const DashboardContainerMobileSDK: FC = () => (
	<DashboardContainer product={ProductType.MOBILE_SDK}>
		<MSdkDashboardTestsPage />
	</DashboardContainer>
);
