import { extractAppMetadata } from './AuthenticationService';
import { DevelopmentToggles } from './TogglesService';

type UserToggles = {
	[key in DevelopmentToggles]: boolean;
};

export const getUserToggles = (): UserToggles => {
	const appMetadata = extractAppMetadata();
	return appMetadata.TOGGLES || {};
};
