export const MAIN_PAGE_NAV_LABEL = 'All Sessions';

export const NAME_NOT_PROVIDED = 'Name-not-Provided';

export const USERS_METRIC_LABEL = 'Users';
export const SESSIONS_METRIC_LABEL = 'Synced sessions';
export const SCANS_METRIC_LABEL = 'Total scans';
export const URLS_METRIC_LABEL = 'Unique URLs analyzed';

export const NOT_SYNCED_YET_POPUP_TITLE = 'Report is Being Uploaded';
export const NOT_SYNCED_YET_POPUP_TEXT =
	"Refresh the Web Flow Analyzer Report page to view the session report once it's ready.";
export const NOT_SYNCED_YET_STATE_KEY = 'showNotSyncedPopup';
