import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { OptionType } from '@evinced-private/ui-common';

import { REFETCH_INTERVAL } from 'src/common/consts/QueryConfigs';
import { SummaryItem } from 'src/common/interfaces/SummaryItem';
import { USAGE_DATA } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { getWebSdkUsageData } from '../services/WebSdkDashboardService';

interface IUseWebSdkUsageDataQueryParams {
	showRecentTest: boolean;
	testPeriod?: OptionType;
	searchValue?: string;
}

export const useWebSdkUsageDataQuery = ({
	showRecentTest,
	testPeriod,
	searchValue
}: IUseWebSdkUsageDataQueryParams): UseQueryResult<SummaryItem[]> =>
	useQuery(
		[USAGE_DATA, showRecentTest, testPeriod, searchValue],
		async (): Promise<SummaryItem[]> => getWebSdkUsageData(showRecentTest, testPeriod, searchValue),
		{
			refetchInterval: REFETCH_INTERVAL
		}
	);
