import React from 'react';

import { TOAST_STATUS } from '@evinced-private/ui-common';

import { IAlertPopup } from 'src/common/components/alert-popup/AlertPopup';

export interface ILocalEvToastProps {
	announcement?: string | JSX.Element;
	status?: TOAST_STATUS;
	show?: boolean;
	onToastClose?: () => void;
}

export type INotificationsContext = {
	alert?: (errorDetails: IAlertPopup) => void;
	toast?: (toastDetails: ILocalEvToastProps) => void;
};

export const NotificationsContext = React.createContext<Partial<INotificationsContext>>({});
