import React, { FC } from 'react';

import classNames from 'classnames';

import { EvIcon } from '@evinced-private/ui-common';

import { GeneralError } from 'src/common/components/errors/general-error/GeneralError';
import { ERROR_LAYOUT_DAID } from 'src/common/consts/data-automation-ids';

import './PropertyErrLoadViewMsg.scss';

interface ILoadErrorMsgLayout {
	mainTitle: string;
	secondaryMsg: string;
	icon: SVGElement;
	className?: string;
}
export const LoadErrorMsgLayout: FC<ILoadErrorMsgLayout> = ({
	mainTitle,
	secondaryMsg,
	icon,
	className,
	children
}) => {
	return (
		<div data-automation-id={ERROR_LAYOUT_DAID} className={classNames('error-layout', className)}>
			<GeneralError title="" displayContactInfo={false}>
				<div className="msg-content">
					<EvIcon icon={icon} title={mainTitle} />
					<div className="main-title">{mainTitle}</div>
					<div className="secondary-msg">{secondaryMsg}</div>
					{children}
				</div>
			</GeneralError>
		</div>
	);
};
