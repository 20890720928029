import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaComponentSampleIssue } from 'src/apps/wfa/services/WfaDashboardService';
import { IComponentSampleIssue } from 'src/apps/wfa/types/ComponentSampleIssue';
import { WFA_COMPONENT_SAMPLE_ISSUE } from 'src/common/providers/reactQueryProvider/QueryKeys';

interface IUseWfaComponentSampleIssueQuery {
	sessionId: string;
	componentId: string;
}

export const useWfaComponentSampleIssueQuery = ({
	sessionId,
	componentId
}: IUseWfaComponentSampleIssueQuery): UseQueryResult<IComponentSampleIssue> => {
	const componentSampleIssueQuery = useQuery(
		[WFA_COMPONENT_SAMPLE_ISSUE, sessionId, componentId],
		async (): Promise<IComponentSampleIssue> => getWfaComponentSampleIssue(sessionId, componentId)
	);

	return componentSampleIssueQuery;
};
