import { Location } from 'history';

import { IEvBreadcrumb } from 'src/common/components/ev-breadcrumbs/EvBreadcrumbs';

import { RoutesHelper } from '../../helpers/RoutesHelper';

import { MAIN_PAGE_NAV_LABEL } from './mfa-consts';

export const getSessionIdParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get('sessionId');
};

export const getSessionNameParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get('sessionName');
};

export const getMfaDashboardBreadcrumbsRoutes = (location: Location<object>): IEvBreadcrumb[] => {
	return [
		{
			id: MAIN_PAGE_NAV_LABEL,
			url: RoutesHelper.getMfaDashboardPath(),
			title: MAIN_PAGE_NAV_LABEL
		},
		{
			id: getSessionIdParam(location),
			url: '',
			title: getSessionNameParam(location)
		}
	];
};
