import ReactGA from 'react-ga';

import { isCI } from 'src/common/helpers/EnvironmentHelper';

import { getUserFromLocalStorage, setGoogleAnalyticsUser } from '../api/LocalStorageApi';
import { Logger } from '../Logger';

interface IHasUserId {
	userId: string;
}

const analyticsCode = process.env.GA_CODE;

let analyticsInitialized = false;
let initializationFailed = false;
let currentUser = null;

const shouldNotRunAnalytics = isCI();

export function initAnalytics(): void {
	try {
		if (shouldNotRunAnalytics) {
			return;
		}
		ReactGA.initialize(analyticsCode);
		// Once we have a user ID we should init it here: ReactGA.set({ userId });
		const user = getUserFromLocalStorage();
		if (user) {
			const { userId } = user;
			ReactGA.set({ userId });
		}
		analyticsInitialized = true;
		Logger.info('analytics was initialized');
	} catch (err) {
		Logger.error('Could not initialize analytics service. Got error:', err);
		initializationFailed = true;
	}
}

export function pageView(path): void {
	if (shouldNotRunAnalytics) {
		return;
	}
	if (!initializationFailed) {
		if (!analyticsInitialized) {
			throw new Error('Analytics service was called without initialization');
		}

		ReactGA.pageview(path);
		return;
	}
	Logger.error('Couldnt call pageview, analytics initialization failed.');
}

export function setCurrentUser(user: IHasUserId): void {
	currentUser = user;
	const { userId } = currentUser;
	setGoogleAnalyticsUser(currentUser);
	ReactGA.set({ userId });
}
