import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { TypesCountBySeverity } from '@evinced-private/ui-common';

import { getSessionIssueTypes } from 'src/apps/wfa/services/WfaDashboardService';
import { WFA_SESSION_TYPES_BREAKDOWN } from 'src/common/providers/reactQueryProvider/QueryKeys';

interface IUseWfaSessionIssueTypesQuery {
	sessionId: string;
}

export const useWfaSessionIssueTypesQuery = ({
	sessionId
}: IUseWfaSessionIssueTypesQuery): UseQueryResult<TypesCountBySeverity[]> => {
	const componentsQuery = useQuery(
		[WFA_SESSION_TYPES_BREAKDOWN, sessionId],
		async (): Promise<TypesCountBySeverity[]> =>
			getSessionIssueTypes({
				sessionId
			}),
		{
			keepPreviousData: true
		}
	);

	return componentsQuery;
};
