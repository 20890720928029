import { apiCall } from 'src/common/services/api/ServerApi';

import { API_URL_PATHS } from '../../../common/consts/ApiUrlPaths';
import { IGetMfaSessionsArgs, IGetMfaSessionsResponse } from '../../mobile-common/types/mfa-types';

export const getMfaSessions = async ({
	tenantId,
	productId
}: IGetMfaSessionsArgs): Promise<IGetMfaSessionsResponse[]> => {
	const res: IGetMfaSessionsResponse[] = await apiCall(
		'GET',
		`tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions`
	);
	return res;
};
