import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router';

import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { TableStateProvider } from 'src/common/providers/dashboard/TableStateProvider';
import { SessionViews } from 'src/common/types/WfaSessionViews';

import { NOT_SYNCED_YET_STATE_KEY } from '../../helpers/WfaDashboardConsts';

import { WfaSingleSessionHeader } from './header/WfaSingleSessionHeader';
import { WfaSingleSessionTabContent } from './views/WfaSingleSessionTabContent';
import { WfaSingleSessionTabs } from './WfaSingleSessionTabs';

import './WfaSingleSessionPage.scss';

interface IWfaSingleSessionPageProps {
	viewId?: SessionViews;
}

export const WfaSingleSessionPage: FC<IWfaSingleSessionPageProps> = ({
	viewId = SessionViews.OVERVIEW
}) => {
	const history = useHistory();
	const { sessionId } = useParams<{ [key: string]: string }>();

	const onSessionNotFoundCallback = (): void => {
		history.push(RoutesHelper.getWfaDashboardPath(), { [NOT_SYNCED_YET_STATE_KEY]: true });
	};

	return (
		<div className="wfa-session-page">
			<WfaSingleSessionHeader
				sessionId={sessionId}
				onSessionNotFoundCallback={onSessionNotFoundCallback}
			/>
			<WfaSingleSessionTabs viewId={viewId} sessionId={sessionId} />
			<TableStateProvider>
				<WfaSingleSessionTabContent sessionId={sessionId} viewId={viewId} />
			</TableStateProvider>
		</div>
	);
};
