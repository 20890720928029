import {
	IFilterDefinition,
	ISelectFilterDefinition,
	ITextFilterDefinition
} from '@evinced-private/ui-common';

import { FilterTypesNames } from 'src/common/consts/app_consts';

const searchFilter: ITextFilterDefinition = {
	id: 'searchValue',
	label: 'Search',
	filterType: 'text',
	defaultValue: '',
	componentProps: {
		placeholder: 'Search'
	}
};

export const mobileSdkTestsFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	{
		id: FilterTypesNames.LABELS,
		label: 'labels',
		filterType: 'select',
		defaultValue: [],
		componentProps: {
			placeholder: 'Select Label',
			allSelectedPlaceholder: 'All Labels',
			someItemsSelectedText: (numberOfItems) => `Labels (${numberOfItems})`,
			allowSelectAll: true,
			isMulti: true,
			className: 'labels-filter-dropdown'
		}
	} as ISelectFilterDefinition
];
