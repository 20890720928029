// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.include-best-practices .ev-switch-label {
  margin-right: 3px;
}`, "",{"version":3,"sources":["webpack://./src/common/components/best-practices-button/BestPracticesButton.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;AAAF","sourcesContent":[".include-best-practices {\n\t.ev-switch-label {\n\t\tmargin-right: 3px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
