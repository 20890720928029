import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getSessionSeverities } from 'src/apps/wfa/services/WfaDashboardService';
import { WFA_SESSION_SEVERITIES } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { SeverityCountType } from '../types/SeverityCountType';

interface IUseWfaSessionSeveritiesQuery {
	sessionId: string;
}

export const useWfaSessionSeveritiesQuery = ({
	sessionId
}: IUseWfaSessionSeveritiesQuery): UseQueryResult<SeverityCountType[]> => {
	const componentsQuery = useQuery(
		[WFA_SESSION_SEVERITIES, sessionId],
		async (): Promise<SeverityCountType[]> =>
			getSessionSeverities({
				sessionId
			}),
		{
			keepPreviousData: true
		}
	);

	return componentsQuery;
};
