import { getUserToggles } from './UserConfigurationService';

export enum DevelopmentToggles {
	CAN_VIEW_DASHBOARDS = 'CAN_VIEW_DASHBOARDS',
	CAN_ACCESS_MFA_DASHBOARD = 'CAN_ACCESS_MFA_DASHBOARD',
	CAN_ACCESS_MOBILE_SDK_DASHBOARD = 'CAN_ACCESS_MOBILE_SDK_DASHBOARD',
	CAN_ACCESS_WEB_SDK_DASHBOARD = 'CAN_ACCESS_WEB_SDK_DASHBOARD',
	CAN_ACCESS_WFA_DASHBOARD = 'CAN_ACCESS_WFA_DASHBOARD',
	ENABLE_AZURE_INTEGRATION = 'ENABLE_AZURE_INTEGRATION',
	ENABLE_WEB_SDK_PROPERTIES = 'ENABLE_WEB_SDK_PROPERTIES'
}

// This is a list of toggles that should always be open
const openPermanently = [];

export const getSingleToggle = (
	toggle: DevelopmentToggles,
	includeUserToggles = false
): boolean => {
	if (openPermanently.includes(toggle)) {
		return true;
	}
	const value = localStorage.getItem(toggle);
	// cast the string value to it's boolean equivilant
	const devToggle = value === 'true';
	return devToggle || (includeUserToggles && getUserToggles()[toggle]);
};

export const setToggle = (toggle: DevelopmentToggles, value: string): void => {
	localStorage.setItem(toggle, value);
};

export const removeToggle = (toggle: DevelopmentToggles): void => {
	localStorage.removeItem(toggle);
};
