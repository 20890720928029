/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_URL_PATHS } from 'src/common/consts/ApiUrlPaths';
import { ProductType, TenantProduct } from 'src/common/interfaces/Tenant';
import { TConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { apiCall } from 'src/common/services/api/ServerApi';

import { Logger } from '../Logger';

const { CENTRAL_CONFIG_PATH } = API_URL_PATHS;

const getPlatformProductID = (products: TenantProduct[]): string => {
	if (!products) {
		return null;
	}
	return products.find((product: TenantProduct) => product.type === ProductType.PLATFORM)?.id;
};

export const getCentralConfig = async (products: TenantProduct[]): Promise<TConfiguration> => {
	const platformProductID = getPlatformProductID(products);
	if (platformProductID) {
		try {
			const configurationResult: TConfiguration = await apiCall(
				'GET',
				`${CENTRAL_CONFIG_PATH}?productId=${platformProductID}`,
				{ isConfigUrl: true }
			);

			return configurationResult;
		} catch (error) {
			Logger.error('Error fetching central configuration', error);
			return null;
		}
	}
	return null;
};
