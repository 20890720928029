import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';

import classNames from 'classnames';

import {
	EvCard,
	EvFilters,
	EvSection,
	EvSpinner,
	EvTitle,
	IFilterValues,
	OptionType,
	TABLE_FILTERS_SR_SUMMARY,
	TABLE_SR_SUMMARY_IDS,
	useSkipLinks
} from '@evinced-private/ui-common';

import omit from 'lodash-es/omit';
import { HorizontalDivider } from 'src/common/components/horizontal-divider/HorizontalDivider';
import { PlatformTable } from 'src/common/components/platform-table/PlatformTable';
import { ReportInfo } from 'src/common/components/report-info/ReportInfo';
import { IFilterOptions } from 'src/common/helpers/DataFilterHelper';
import { flattenObject } from 'src/common/helpers/ObjectsHelper';
import { buildFullTableId } from 'src/common/helpers/TableStateHelper';
import { useTableState } from 'src/common/providers/dashboard/TableStateProvider';
import { usePropertiesMapQuery } from 'src/common/services/properties/hooks/usePropertiesMapQuery';
import { DevelopmentToggles, getSingleToggle } from 'src/common/services/TogglesService';

import {
	WEB_SDK_TEST_ISSUES_TABLE_ID,
	WEB_SDK_TEST_TABLE_ID
} from '../../../../common/pages/dashboards-container/dashboard_consts';
import {
	DASHBOARD_ERROR_MSG,
	WEB_SDK_TEST_INFO_ERROR_MSG
} from '../../../../common/pages/dashboards-container/errorMessages';
import { NAME_NOT_PROVIDED, webSdkIssuesFilterDefinitions } from '../../helpers/WebSdkConsts';
import { getWebSdkInfoItems } from '../../helpers/WebSdkHelper';
import { getWebSdkTestIssuesColumns } from '../../helpers/WebSdkTableHelper';
import { useWebSdkIssuesFilterOptionsQuery } from '../../hooks/useWebSdkIssuesFilterOptionsQuery';
import { useWebSdkIssuesQuery } from '../../hooks/useWebSdkIssuesQuery';
import { useWebSdkTestBreadcrumbs } from '../../hooks/useWebSdkTestBreadcrumbs';
import { useWebSdkTestDetailsQuery } from '../../hooks/useWebSdkTestDetailsQuery';
import { WebSdkIssuesPageError } from '../error-pages/WebSdkIssuesPageError';

import './WebSdkTestIssuesPage.scss';

const enableWebSdkProperties = getSingleToggle(DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES);

export const WebSdkTestIssuesPage: FC = () => {
	const { getTableStateById, onFilterChange } = useTableState();
	const { runId } = useParams<{ [key: string]: string }>();
	const tableId = useMemo(() => buildFullTableId(WEB_SDK_TEST_ISSUES_TABLE_ID, runId), [runId]);
	const { filters: testsTableFilters } = getTableStateById(WEB_SDK_TEST_TABLE_ID);
	const tableState = getTableStateById(tableId);
	const { filters, tableSort, paginationInfo } = tableState;

	const filtersWithPropertiesFromTestsTable = useMemo(
		() => ({
			...filters,
			// if properties are enabled and there's a property filter applied
			// in the tests page, it should be applied here too
			...(enableWebSdkProperties
				? {
						properties:
							(filters.properties?.length ? filters.properties : testsTableFilters?.properties) ??
							[]
					}
				: {})
		}),
		[filters, testsTableFilters?.properties]
	);

	const testDetailsQuery = useWebSdkTestDetailsQuery(runId);

	useWebSdkTestBreadcrumbs(runId, testDetailsQuery.data?.name, testDetailsQuery?.data?.runTime);
	useSkipLinks(!testDetailsQuery.isLoading);

	const issuesFilterOptionsQuery = useWebSdkIssuesFilterOptionsQuery(runId);

	const propertiesQuery = usePropertiesMapQuery(enableWebSdkProperties);

	const isPropertiesLoading = enableWebSdkProperties && propertiesQuery.isLoading;

	const propertiesOptions = useMemo((): OptionType[] => {
		if (!enableWebSdkProperties || isPropertiesLoading || testDetailsQuery.isLoading) {
			return [];
		}

		const options: OptionType[] = [];
		testDetailsQuery.data?.propertyIds.forEach((propertyId) => {
			if (propertiesQuery.data?.has(propertyId)) {
				options.push({
					value: propertyId,
					label: propertiesQuery.data.get(propertyId) as string
				});
			}
		});

		return options;
	}, [
		isPropertiesLoading,
		propertiesQuery.data,
		testDetailsQuery.data?.propertyIds,
		testDetailsQuery.isLoading
	]);

	const issuesQuery = useWebSdkIssuesQuery({
		runId,
		filters: filtersWithPropertiesFromTestsTable,
		sort: tableSort?.[0],
		page: paginationInfo.page,
		pageSize: paginationInfo.sizePerPage
	});

	const handleApply = (appliedFilters: IFilterValues): void => {
		const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
		onFilterChange(tableId, flatFilters);
	};

	const total = issuesQuery.data?.total ?? 0;
	const unfilteredTotal = issuesQuery.data?.unfilteredTotal ?? 0;

	const testName = testDetailsQuery.data?.name || NAME_NOT_PROVIDED;

	const testInfoSection = useMemo(() => {
		if (testDetailsQuery.isError || (enableWebSdkProperties && propertiesQuery.isError)) {
			return <EvCard title={DASHBOARD_ERROR_MSG}>{WEB_SDK_TEST_INFO_ERROR_MSG}</EvCard>;
		}

		if (testDetailsQuery.isLoading || isPropertiesLoading) {
			return <EvSpinner />;
		}

		return (
			<>
				<EvTitle titleText={testName} headingLevel={1} className="main-title page-heading" />
				<EvSection
					ariaLabel={`Test ${testName} info`}
					skipLinkId="metadata-section"
					ariaLive="polite"
					className={classNames('dashboard-section', 'web-sdk-test-info-section')}
				>
					<ReportInfo
						titleText="Test Info"
						reportInfo={getWebSdkInfoItems(propertiesQuery.data)}
						reportDetails={testDetailsQuery.data}
					/>
				</EvSection>
			</>
		);
	}, [
		testDetailsQuery.isError,
		testDetailsQuery.isLoading,
		testDetailsQuery.data,
		propertiesQuery.isError,
		propertiesQuery.data,
		isPropertiesLoading,
		testName
	]);

	const remoteOptions = {
		...(issuesFilterOptionsQuery.data ?? {}),
		properties: propertiesOptions ?? []
	};

	const initialValues = {
		text: { searchValue: filtersWithPropertiesFromTestsTable?.searchValue },
		select: omit(filtersWithPropertiesFromTestsTable, 'searchValue')
	};

	const isFilterOptionsLoading = isPropertiesLoading || issuesFilterOptionsQuery.isLoading;

	const renderTableAndFilters = (): React.ReactNode => {
		if (issuesQuery.isError || issuesFilterOptionsQuery.isError) {
			return <WebSdkIssuesPageError />;
		}

		if (!issuesQuery.isLoading && !issuesQuery.isPreviousData && unfilteredTotal === 0) {
			return <WebSdkIssuesPageError isNoData />;
		}

		return (
			<>
				<EvSection
					ariaLabel="WEB SDK Test's issues filters"
					ariaDescribedby={TABLE_FILTERS_SR_SUMMARY}
					skipLinkId="filters-section"
					ariaLive="polite"
					className={classNames('dashboard-section', 'web-sdk-test-issues-filters-section')}
				>
					{isFilterOptionsLoading ? (
						<EvSpinner />
					) : (
						<div className="filters-area">
							<div className="filter-line">
								<EvFilters
									className="data-filters"
									filterDefinitions={webSdkIssuesFilterDefinitions}
									initialValues={initialValues}
									filterOptions={remoteOptions}
									isFilterOptionsLoaded={!isFilterOptionsLoading}
									onApply={handleApply}
									onReset={handleApply}
								/>
							</div>
						</div>
					)}
				</EvSection>
				<EvSection
					ariaLabel="WEB SDK Test's issues Table"
					ariaLive="polite"
					ariaDescribedby={TABLE_SR_SUMMARY_IDS}
					skipLinkId="main-section"
					className={classNames('dashboard-section', 'issues-table-section')}
				>
					<EvTitle titleText="Detected issues" headingLevel={2} className="page-heading" />
					<PlatformTable
						isTableLoading={issuesQuery.isLoading || issuesQuery.isPreviousData}
						tableId={tableId}
						tableData={issuesQuery.data?.rows ?? []}
						totalCount={unfilteredTotal}
						totalPaginationableResults={total}
						className="test-issues-table"
						persistTableState
						columns={getWebSdkTestIssuesColumns(runId, testDetailsQuery.data?.runTime, testName)}
						options={{
							keyField: 'id',
							title: 'Web SDK Test Issues',
							caption: 'Web SDK Test Issues table',
							pagination: true,
							dataType: 'issues',
							remote: true
						}}
						withUrlSwitcher={true}
					/>
				</EvSection>
			</>
		);
	};

	return (
		<div className="web-sdk-test-issues-page">
			{testInfoSection}
			<HorizontalDivider />
			{renderTableAndFilters()}
		</div>
	);
};
