import React, { FC } from 'react';

import { BUTTON_TYPES, EvContactUsButton } from '@evinced-private/ui-common';

import {
	CONTACT_US_FORM_DESCRIPTION,
	CONTACT_US_FORM_TITLE
} from 'src/common/consts/contact-us-consts';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from 'src/common/services/analytics/HubspotService';

const MFA_DASHBOARD_CONTACT_US_PRODUCT = 'Mobile Flow Analyzer Dashboard';
const WFA_DASHBOARD_CONTACT_US_PRODUCT = 'Web Flow Analyzer Dashboard';
const MSDK_DASHBOARD_CONTACT_US_PRODUCT = 'Mobile SDK Dashboard';
const WSDK_DASHBOARD_CONTACT_US_PRODUCT = 'Web SDK Dashboard';

const getContactUsProduct = (product: ProductType): string => {
	switch (product) {
		case ProductType.MOBILE_FLOW_ANALYZER:
			return MFA_DASHBOARD_CONTACT_US_PRODUCT;
		case ProductType.MOBILE_SDK:
			return MSDK_DASHBOARD_CONTACT_US_PRODUCT;
		case ProductType.WEB_SDK:
			return WSDK_DASHBOARD_CONTACT_US_PRODUCT;
		case ProductType.WEB_FLOW_ANALYZER:
			return WFA_DASHBOARD_CONTACT_US_PRODUCT;
		default:
			return null;
	}
};

interface IContactUsButtonProps {
	type?: BUTTON_TYPES;
	product?: ProductType;
	accessibilityProps?: Record<string, unknown>;
}
export const ContactUsButton: FC<IContactUsButtonProps> = ({
	type = BUTTON_TYPES.PRIMARY,
	product,
	accessibilityProps
}) => {
	const { tenant, user } = useUserTenant();
	return (
		<EvContactUsButton
			type={type}
			formTitle={CONTACT_US_FORM_TITLE}
			description={CONTACT_US_FORM_DESCRIPTION}
			portalId={hubspotAPIKey}
			userEmail={user?.email || ''}
			userTenant={tenant?.id}
			additionalInfo={{ isRequired: true, label: 'Message' }}
			product={getContactUsProduct(product)}
			accessibilityProps={accessibilityProps}
		/>
	);
};
