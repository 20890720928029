import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';

import { Logger } from '../services/Logger';

export const initialPageScrollPosition = {};
interface IPageScrollPositionContext {
	pagesScrollPositionState: IPageScrollPosition;
	getCurrentPageSavedScrollPosition: (pageId: string) => { elementSelector: string };
	setLastClickedElementScrollPosition: (pageId: string, elementSelector: string) => void;
	scrollToSavedPosition: (pageId: string, loaded: boolean) => void;
}

interface IPageScrollPosition {
	[id: string]: {
		elementSelector: string;
	};
}

const PageScrollPositionContext = createContext({} as IPageScrollPositionContext);
export const usePageScrollPositionState = (): IPageScrollPositionContext =>
	useContext(PageScrollPositionContext);

export const PageScrollPositionProvider: FC<{
	state: IPageScrollPosition;
}> = ({ state, children }) => {
	const [pagesScrollPositionState, setPagesScrollPositionState] =
		useState<IPageScrollPosition>(state);

	const getCurrentPageSavedScrollPosition = useCallback(
		(pageId: string): { elementSelector: string } => {
			return pagesScrollPositionState[pageId];
		},
		[pagesScrollPositionState]
	);

	const setLastClickedElementScrollPosition = useCallback(
		(pageId: string, elementSelector: string): void => {
			const updatedPosition = {
				[pageId]: {
					elementSelector
				}
			};
			const pagesPositionState = {
				...pagesScrollPositionState,
				...updatedPosition
			};
			setPagesScrollPositionState(pagesPositionState);
		},
		[pagesScrollPositionState, setPagesScrollPositionState]
	);

	const scrollToSavedPosition = useCallback(
		(pageId: string, loaded: boolean): void => {
			const { elementSelector } = getCurrentPageSavedScrollPosition(pageId);
			if (!elementSelector) {
				return;
			}

			const elementToFocus = document.querySelector(elementSelector) as HTMLElement;

			if (elementToFocus && loaded) {
				try {
					elementToFocus.scrollIntoView();
					elementToFocus.focus();
				} catch (error) {
					Logger.error(error);
				}

				/**
				 * in order to avoid the position to be saved even after tabs navigation/pages
				 * navigations that are not drill down - right after thr scroll to element, we
				 * need to remove it.
				 */
				setLastClickedElementScrollPosition(pageId, null);
			}
		},
		[getCurrentPageSavedScrollPosition, setLastClickedElementScrollPosition]
	);

	const value = useMemo(
		() => ({
			pagesScrollPositionState,
			scrollToSavedPosition,
			setLastClickedElementScrollPosition,
			getCurrentPageSavedScrollPosition
		}),
		[
			pagesScrollPositionState,
			scrollToSavedPosition,
			setLastClickedElementScrollPosition,
			getCurrentPageSavedScrollPosition
		]
	);
	return (
		<PageScrollPositionContext.Provider value={value}>
			{children}
		</PageScrollPositionContext.Provider>
	);
};
