import { API_URL_PATHS } from 'src/common/consts/ApiUrlPaths';

import { IUserInvitation } from '../interfaces/UserInvitation';
import { INVITATION_STATE } from '../interfaces/UserInvitationState';

import { apiCall } from './api/ServerApi';

const { USER_INVITATIONS_URL_PATH } = API_URL_PATHS;

export enum PUBLIC_INVITATON_STATE {
	SIGNED_UP = 'SIGNED_UP',
	NOT_SIGNED_UP = 'NOT_SIGNED_UP'
}
type PublicStateResponse = {
	state: PUBLIC_INVITATON_STATE;
};
export async function getInvitationTokenPublicState(
	invitationToken: string
): Promise<PublicStateResponse> {
	return apiCall('GET', `${USER_INVITATIONS_URL_PATH}/public-state?token=${invitationToken}`, {
		publicAPI: true
	});
}
export async function getAllUserPendingInvitations(): Promise<IUserInvitation[]> {
	const invitations = await apiCall('GET', USER_INVITATIONS_URL_PATH);
	return invitations.filter((i: IUserInvitation) => i.state === INVITATION_STATE.PENDING);
}
