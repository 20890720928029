import { API_URL_PATHS } from 'src/common/consts/ApiUrlPaths';

import { IUser } from '../interfaces/User';

import { callGETAndCache } from './api/ServerCacheApi';

const { USER_URL_PATH } = API_URL_PATHS;

export async function getUserMetadata(): Promise<IUser> {
	const user: IUser = await callGETAndCache(USER_URL_PATH);
	return user;
}
