import { FC } from 'react';
import { useHistory } from 'react-router';

import { useConfiguration } from '../../../providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import { getHomepageRedirectToLink } from '../../../services/HomePageRedirectionService';

export const DashboardContainerHome: FC = () => {
	const history = useHistory();
	const { tenant } = useUserTenant();
	const { getToggle } = useConfiguration();

	const redirectTo = getHomepageRedirectToLink(tenant, getToggle);
	history.push(redirectTo);
	return null;
};
