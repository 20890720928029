const GA_LOCAL_STORAGE_KEY = 'GOOGLE_ANALYTICS_INFO';
const ACCESS_TOKEN_NAME = 'evincedAccessToken';
const ID_TOKEN_NAME = 'evincedIdToken';
const JIRA_ACCESS_TOKEN_NAME = 'jiraAccessToken';
const AZURE_ACCESS_TOKEN_NAME = 'azureAccessToken';

export function getFromLocalStorage<T>(key: string): T {
	const item: string = localStorage.getItem(key);
	if (item) {
		return JSON.parse(item);
	}
	return null;
}

export function saveToLocalStorage<T>(key: string, value: T): void {
	localStorage.setItem(key, JSON.stringify(value));
}

export function deleteFromLocalStorage(key): void {
	localStorage.removeItem(key);
}

export function getUserFromLocalStorage(): { userId: string } {
	const gaUser = localStorage.getItem(GA_LOCAL_STORAGE_KEY);
	if (gaUser) {
		return JSON.parse(gaUser);
	}
	return null;
}

export function setGoogleAnalyticsUser(user: { userId: string }): void {
	localStorage.setItem(GA_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function getAccessTokenFromLocalStorage(): string {
	return localStorage.getItem(ACCESS_TOKEN_NAME);
}

export function setAccessToken(token): void {
	localStorage.setItem(ACCESS_TOKEN_NAME, token);
}

export function getIdTokenFromLocalstorage(): string {
	return localStorage.getItem(ID_TOKEN_NAME);
}

export function setIdToken(token): void {
	localStorage.setItem(ID_TOKEN_NAME, token);
}

export function removeAuthenticationTokens(): void {
	localStorage.removeItem(ACCESS_TOKEN_NAME);
	localStorage.removeItem(ID_TOKEN_NAME);
}

export function removeValuesOnLogout(): void {
	removeAuthenticationTokens();
	localStorage.removeItem(GA_LOCAL_STORAGE_KEY);
}

export function setJiraAccessToken(token): void {
	localStorage.setItem(JIRA_ACCESS_TOKEN_NAME, token);
}

export function getJiraAccessToken(): string {
	return localStorage.getItem(JIRA_ACCESS_TOKEN_NAME);
}

export function removeJiraAccessToken(): void {
	localStorage.removeItem(JIRA_ACCESS_TOKEN_NAME);
}

export function setAzureAccessToken(token): void {
	localStorage.setItem(AZURE_ACCESS_TOKEN_NAME, token);
}

export function getAzureAccessToken(): string {
	return localStorage.getItem(AZURE_ACCESS_TOKEN_NAME);
}

export function removeAzureAccessToken(): void {
	localStorage.removeItem(AZURE_ACCESS_TOKEN_NAME);
}
