import { IGetHTMLReportArgs, IMfaEndpointResponse } from 'src/apps/mobile-common/types/mfa-types';
import { apiCall } from 'src/common/services/api/ServerApi';

import { API_URL_PATHS } from '../../../common/consts/ApiUrlPaths';

export const getHTMLReport = async ({
	tenantId,
	productId,
	sessionId
}: IGetHTMLReportArgs): Promise<string> => {
	const res: IMfaEndpointResponse = await apiCall(
		'GET',
		`/tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions/${sessionId}/html-report`
	);
	return res.link;
};

export const deleteSessionById = async (
	tenantId: string,
	productId: string,
	sessionId: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const res = await apiCall(
		'DELETE',
		`/tenants/${tenantId}/products-export/${productId}/sessions/${sessionId}`
	);
	return res;
};
