import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaUsageData } from 'src/apps/wfa/services/WfaDashboardService';
import { REFETCH_INTERVAL } from 'src/common/consts/QueryConfigs';
import { IWfaSessionsFilterOption } from 'src/common/helpers/DataFilterHelper';
import { SummaryItem } from 'src/common/interfaces/SummaryItem';
import { WFA_USAGE_DATA } from 'src/common/providers/reactQueryProvider/QueryKeys';

export const useWfaUsageDataQuery = (
	filters: IWfaSessionsFilterOption
): UseQueryResult<SummaryItem[]> => {
	const query = useQuery(
		[WFA_USAGE_DATA, filters],
		async (): Promise<SummaryItem[]> => getWfaUsageData(filters),
		{
			refetchInterval: REFETCH_INTERVAL
		}
	);

	return query;
};
