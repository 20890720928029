// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-details-popup .spinner-wrapper {
  width: 1050px;
  height: 500px;
}
.component-details-popup .ev-popup-container {
  max-width: 1050px;
}`, "",{"version":3,"sources":["webpack://./src/apps/wfa/components/component-details-popup/ComponentDetailsPopup.scss"],"names":[],"mappings":"AACC;EACC,aAAA;EACA,aAAA;AAAF;AAGC;EACC,iBAAA;AADF","sourcesContent":[".component-details-popup {\n\t.spinner-wrapper {\n\t\twidth: 1050px;\n\t\theight: 500px;\n\t}\n\n\t.ev-popup-container {\n\t\tmax-width: 1050px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
