import React, { FC } from 'react';

import { ProductType } from 'src/common/interfaces/Tenant';
import { DashboardContainer } from 'src/common/pages/dashboards-container/DashboardContainer';

import { MFASessionsPage } from '../sessions/MfaSessionsPage';

export const DashboardContainerMfa: FC = () => (
	<DashboardContainer product={ProductType.MOBILE_FLOW_ANALYZER}>
		<MFASessionsPage />
	</DashboardContainer>
);
