import { RUN_ID_PARAM_NAME } from 'src/common/services/queries/CommonParams';
import { ConditionId } from 'src/common/types/graphql.generated';

import { QueryVariablesBuilder } from './QueryVariablesBuilder';

type TRunIdQueryVariable = {
	runId: ConditionId;
};

export const buildRunIdParam = (runId: string): TRunIdQueryVariable => {
	const variablesBuilder = new QueryVariablesBuilder<TRunIdQueryVariable>();
	return variablesBuilder.addConditionParam(RUN_ID_PARAM_NAME, runId).getVariables();
};
