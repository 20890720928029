import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getSessionSummary } from 'src/apps/wfa/services/WfaDashboardService';
import { SessionSummaryResult } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { WFA_SESSION_SUMMARY } from 'src/common/providers/reactQueryProvider/QueryKeys';

interface IUseWfaSessionSummaryQuery {
	sessionId: string;
}

export const useWfaSessionSummaryQuery = ({
	sessionId
}: IUseWfaSessionSummaryQuery): UseQueryResult<SessionSummaryResult> => {
	const sessionSummaryQuery = useQuery(
		[WFA_SESSION_SUMMARY, sessionId],
		async (): Promise<SessionSummaryResult> =>
			getSessionSummary({
				sessionId
			})
	);

	return sessionSummaryQuery;
};
