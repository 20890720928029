// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selector-with-copy {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/common/components/text-block-with-title/selector-text-block/SelectorTextBlock.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;AACD","sourcesContent":[".selector-with-copy {\n\tdisplay: flex;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
