import React, { FC, useMemo, useState } from 'react';

import { EvErrorCard, TITLE_MODES } from '@evinced-private/ui-common';

import { PlatformPopup } from 'src/common/components/platform-popup/PlatformPopup';
import { ProductType } from 'src/common/interfaces/Tenant';
import { DASHBOARD_ERROR_MSG } from 'src/common/pages/dashboards-container/errorMessages';

import { WfaComponentDetailsBlock } from '../WfaComponentDetailsBlock';

import './ComponentDetailsPopup.scss';

interface IComponentDetailsPopupProps {
	runId: string;
	componentId: string;
	totalIssues: number;
	totalPages: number;
	product: ProductType;
	onClose?: () => void;
}

export const ComponentDetailsPopup: FC<IComponentDetailsPopupProps> = ({
	runId,
	componentId,
	onClose,
	product,
	totalIssues,
	totalPages
}: IComponentDetailsPopupProps) => {
	const [error, setError] = useState(null);

	const title = `Component ${componentId}`;

	const componentDetailsBlock = useMemo(() => {
		switch (product) {
			case ProductType.WEB_FLOW_ANALYZER:
				return (
					<WfaComponentDetailsBlock
						runId={runId}
						componentId={componentId}
						totalIssues={totalIssues}
						totalPages={totalPages}
					/>
				);
			default:
				return null;
		}
	}, [componentId, product, runId, totalIssues, totalPages]);

	return (
		<PlatformPopup
			isOpen
			title={error ? 'Error' : title}
			className="component-details-popup"
			isCentered={!!error}
			isBgTransparent
			isControlled
			onClose={() => {
				setError?.(null);
				onClose?.();
			}}
			popupWidth={1050}
			titleMode={TITLE_MODES.MAIN}
		>
			{error ? (
				<EvErrorCard title="Error" contactUsMessage={DASHBOARD_ERROR_MSG} />
			) : (
				componentDetailsBlock
			)}
		</PlatformPopup>
	);
};
