import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { EvSpinner } from '@evinced-private/ui-common';

import {
	getEmailFromUrl,
	getInvitationTokenFromUrl
} from 'src/common/helpers/InvitationTokenHelper';

import { AuthToken } from './AuthToken';

const invitationToken = getInvitationTokenFromUrl();
const userEmail = getEmailFromUrl();

export const SignupPage = withAuthenticationRequired(AuthToken, {
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		...(invitationToken ? { loginOptions: { invitationToken } } : {}),
		authorizationParams: {
			screen_hint: 'signup',
			login_hint: userEmail
		}
	}
});
