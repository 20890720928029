import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { OptionType } from '@evinced-private/ui-common';

import { REFETCH_INTERVAL } from 'src/common/consts/QueryConfigs';
import { WEB_SDK_TEST_LABELS } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { getWebSdkTestLabels } from '../services/WebSdkDashboardService';

export const useWebSdkTestLabelsQuery = (): UseQueryResult<OptionType[]> =>
	useQuery([WEB_SDK_TEST_LABELS], async (): Promise<OptionType[]> => getWebSdkTestLabels(), {
		refetchInterval: REFETCH_INTERVAL
	});
