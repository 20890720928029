// This file was copied from site scanner. Planning to move it to ui-common.
import { flatMap, keyBy, uniqBy } from 'lodash-es';
import MarkdownIt from 'markdown-it';

import { OptionType } from '@evinced-private/ui-common';

import {
	AZURE_FIELDS,
	IAzureClassificationNode,
	IAzureField,
	IAzureFieldType,
	IAzureIssueType,
	IAzureMetaData,
	IAzureProject,
	IAzureWorkItemType
} from '../../../types/AzureTypes';
import { Dictionary } from '../../../types/LodashTypes';

const BASE_URL = 'https://dev.azure.com';
const USERS_BASE_URL = 'https://vssps.dev.azure.com/';

export const getPaths = (host: string): string[] => {
	return new URL(host).pathname.split('/');
};

export const getBaseUrl = (): string => {
	return BASE_URL;
};

export const getOrganizationNameUrl = (host: string): string => {
	// https://dev.azure.com/{organization}
	const paths = getPaths(host);
	return `${getBaseUrl()}/${paths[1]}`;
};

export const getProjectNameUrl = (host: string): string => {
	// https://dev.azure.com/{organization}/{project}
	const paths = getPaths(host);
	return `${BASE_URL}/${paths[1]}/${paths[2]}`;
};

export const getUsersUrl = (host: string): string => {
	// https://vssps.dev.azure.com/{organization}
	const paths = getPaths(host);
	return `${USERS_BASE_URL}${paths[1]}`;
};

export const getProjectsOptionType = (projects: IAzureProject[]): IAzureProject[] => {
	return projects.map((project) => {
		return { ...project, value: project.id, label: project.name };
	});
};

export const getProjectTypes = (projectIssueTypes: IAzureIssueType[]): OptionType[] => {
	if (!projectIssueTypes.length) {
		return [];
	}
	// the hidden types are always will be in the last item in the array.
	const hiddenIssueTypes: IAzureWorkItemType[] = projectIssueTypes.slice(-1)[0].workItemTypes;
	// extract the rest of the array.
	const projectIssueTypesWithoutHidden: IAzureIssueType[] = projectIssueTypes.slice(0, -1);
	// convert to set for uniq strings.
	const hiddenTypesSet: Set<string> = new Set<string>(
		hiddenIssueTypes.map((hiddenType: IAzureWorkItemType) => hiddenType.name)
	);
	// iterates the array and convert to an OptionType object and filter out the hidden types.
	const filtered: OptionType[] = flatMap(
		projectIssueTypesWithoutHidden,
		(projectIssue: IAzureIssueType) => projectIssue.workItemTypes
	)
		.filter((type: IAzureWorkItemType) => !hiddenTypesSet.has(type.name))
		.map((type: IAzureWorkItemType): OptionType => ({ label: type.name, value: type.name }));

	// returns only uniq values.
	return uniqBy(filtered, (item: OptionType) => item.label);
};

export const getFormattedFields = (
	fields: Dictionary<IAzureField>,
	selectedIssueTypeFields: IAzureFieldType[]
): Dictionary<IAzureFieldType> => {
	const mergeFields: IAzureFieldType[] = selectedIssueTypeFields.map((field) => {
		let type: string = fields[field.referenceName]?.type;

		if (field.allowedValues.length || fields[field.referenceName]?.isIdentity) {
			type = 'select';
		} else if (field.referenceName === AZURE_FIELDS.DESCRIPTION || field.type === 'plainText') {
			type = 'textarea';
		} else if (!type || type === 'guid' || type === 'history' || type === 'html') {
			type = 'string';
		}

		return { ...field, type, value: '', label: field.name };
	});

	return keyBy(mergeFields, 'referenceName');
};

export const getFieldsMetaData = (fields: Dictionary<IAzureFieldType>): IAzureMetaData[] => {
	return Object.values(fields)
		.filter((field) => field.value)
		.map((field) => {
			let { value } = field;
			if (field.referenceName === AZURE_FIELDS.DESCRIPTION) {
				// Azure doesn't understand standard markdown syntax, replacing it with html tags
				const md = new MarkdownIt({
					html: true,
					linkify: true,
					typographer: true
				});
				const formattedString = (field.value as string).replace(
					/{code:html}|{code}|{noformat}/gi,
					'```\n'
				);
				value = md.render(formattedString);
			}
			return {
				op: 'add',
				path: `/fields/${field.referenceName}`,
				value: value as string
			};
		});
};

export const replaceProjectName = (host: string, projectName: string): string => {
	const paths = getPaths(host);

	return `${BASE_URL}/${paths[1]}/${projectName}`;
};

export const getClassificationNodes = (nodes: IAzureClassificationNode[]): string[] => {
	// traverse the tree nodes and return each path.
	const flatten = (arr: IAzureClassificationNode[]): string[] =>
		arr.flatMap(({ path, children = [] }) => [path, ...flatten(children)]);
	const options: string[] = flatten(nodes).map((path): string => {
		// need to remove field name(Area/Iteration) from path for post request.
		// {project name}\Area\{area path} -> {project name}\{area path}

		const formattedPath = path.includes('Area')
			? path.replace('\\Area', '')
			: path.replace('\\Iteration', '');
		return formattedPath;
	});

	return options;
};

export const openUrlInNewTab = (url: string): void => {
	window.open(url, '_blank');
};

export const dataURItoBlob = (dataURI): Blob => {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs
	const byteString = atob(dataURI.split(',')[1]);
	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	// write the ArrayBuffer to a blob, and you're done
	return new Blob([ab], { type: mimeString });
};

export const addAttachmentToPayload = (
	attachmentUrl: string,
	payload: IAzureMetaData[]
): IAzureMetaData[] => {
	const payloadWithAttachment = [...payload];

	if (attachmentUrl) {
		payloadWithAttachment.push({
			op: 'add',
			path: '/relations/-',
			value: {
				rel: 'AttachedFile',
				url: attachmentUrl,
				attributes: {
					comment: 'screenshot'
				}
			}
		});
	}

	return payloadWithAttachment;
};
