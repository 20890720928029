import React, { FC } from 'react';

import { UseQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';

import { EvSection, EvTitle, TABLE_SR_SUMMARY_IDS } from '@evinced-private/ui-common';

import {
	WFA_DASHBOARD_PAGE_TABLE_SECTION_ID,
	WFA_DASHBOARD_PAGE_TABLE_TITLE_ID
} from 'src/apps/wfa/helpers/WfaDashboardAutomationIds';
import { TWfaSessionsResult } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { PlatformTable } from 'src/common/components/platform-table/PlatformTable';
import { WFA_SESSIONS_TABLE_ID } from 'src/common/pages/dashboards-container/dashboard_consts';

import { getWfaSessionsColumns } from './WfaSessionsTableHelper';

import './WfaSessionsTable.scss';

interface IWfaDashboardTableProps {
	sessionsQuery: UseQueryResult<TWfaSessionsResult>;
	unfilteredTotal: number;
}

const TABLE_KEY_FIELD = 'sessionId';
const TABLE_TITLE = 'WFA Sessions';
const TABLE_CAPTION = 'WFA Sessions table';
const DATA_TYPE = 'sessions';

export const WfaSessionsTable: FC<IWfaDashboardTableProps> = ({
	sessionsQuery,
	unfilteredTotal
}) => {
	const total: number = sessionsQuery.data?.total ?? 0;

	const isTableLoading =
		sessionsQuery.isLoading ||
		sessionsQuery.isPreviousData ||
		(sessionsQuery.isStale && sessionsQuery.isRefetching);

	return (
		<EvSection
			ariaLabel="Web Flow Analyzer sessions Table"
			ariaLive="polite"
			ariaDescribedby={TABLE_SR_SUMMARY_IDS}
			skipLinkId="main-section"
			className={classNames('dashboard-section', 'wfa-sessions-table')}
			dataAutomationId={WFA_DASHBOARD_PAGE_TABLE_SECTION_ID}
		>
			<EvTitle
				titleText="All sessions"
				headingLevel={2}
				className="page-heading"
				dataAutomationId={WFA_DASHBOARD_PAGE_TABLE_TITLE_ID}
			/>
			<PlatformTable
				isTableLoading={isTableLoading}
				tableId={WFA_SESSIONS_TABLE_ID}
				tableData={sessionsQuery.data?.rows}
				totalPaginationableResults={total}
				totalCount={unfilteredTotal}
				persistTableState
				columns={getWfaSessionsColumns()}
				options={{
					keyField: TABLE_KEY_FIELD,
					title: TABLE_TITLE,
					caption: TABLE_CAPTION,
					pagination: true,
					dataType: DATA_TYPE,
					remote: true
				}}
			/>
		</EvSection>
	);
};
