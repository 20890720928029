import React, { FC, useCallback, useEffect, useState } from 'react';

import { omit } from 'lodash-es';

import {
	EvFilters,
	EvSection,
	EvSpinner,
	EvTitle,
	IFilterValues,
	SORT_ORDER,
	TABLE_SR_SUMMARY_IDS,
	TableSortOption,
	useSkipLinks
} from '@evinced-private/ui-common';

import { DownloadCSV } from 'src/apps/mfa/components/download-csv/DownloadCSV';
import {
	addHeaderColumns,
	createHeadersOutOfTableColumns
} from 'src/apps/mfa/helpers/CsvDownloadHelper';
import { MfaSession } from 'src/apps/mobile-common/types/mfa-types';
import { BestPracticesButton } from 'src/common/components/best-practices-button/BestPracticesButton';
import { ContactUsButton } from 'src/common/components/contact-us/ContactUsButton';
import CloudStatusIcon from 'src/common/components/icons/CloudStatusIcon.svg';
import { PlatformTable } from 'src/common/components/platform-table/PlatformTable';
import { SummaryPane } from 'src/common/components/summary-pane/SummaryPane';
import { IFilterOptions } from 'src/common/helpers/DataFilterHelper';
import { flattenObject } from 'src/common/helpers/ObjectsHelper';
import { SummaryItem } from 'src/common/interfaces/SummaryItem';
import { ProductType } from 'src/common/interfaces/Tenant';
import { LoadErrorMsgLayout } from 'src/common/pages/property/LoadErrorMsgLayout';
import {
	ITableStateContext,
	useTableState
} from 'src/common/providers/dashboard/TableStateProvider';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { Logger } from 'src/common/services/Logger';

import { useInterval } from '../../../../common/hooks/useInterval';
import {
	LAST_MONTH_USAGE_TOOLTIP,
	MFA_SESSIONS_TABLE_ID
} from '../../../../common/pages/dashboards-container/dashboard_consts';
import {
	NO_SYNCED_SESSIONS_MSG,
	NO_SYNCED_SESSIONS_TITLE
} from '../../../../common/pages/dashboards-container/errorMessages';
import {
	assertSessionExtraData,
	buildMFASessionsList,
	buildMFASummaryPaneData,
	buildTableFilters
} from '../../helpers/mfa-dashboard-helpers';
import {
	getFilteredMFASessions,
	getMfaSessionsColumns
} from '../../helpers/mfa-sessions-table-helper';
import { MfaSessionsPageFilterDefinitions } from '../../helpers/MfaSessionsPageFilterDefinitionsHelper';
import { getMfaSessions } from '../../services/MfaSessionsService';

import './MFASessionsPage.scss';

const REFRESH_TABLE_INTERVAL = 20000;

const defaultSort = {
	dataField: 'lastModified',
	order: SORT_ORDER.DESC
};
const formatDateColumns = ['lastModified'];

export const MFASessionsPage: FC = () => {
	const { tenant, getProductIdByType } = useUserTenant();
	const productId = getProductIdByType(ProductType.MOBILE_FLOW_ANALYZER);

	const tableStateContext: ITableStateContext = useTableState();
	const tableState = tableStateContext.getTableStateById(MFA_SESSIONS_TABLE_ID);

	const [isLoading, setIsLoading] = useState(true);
	const [allMfaSessions, setAllMfaSessions] = useState<MfaSession[]>(null);
	const [tableData, setTableData] = useState<MfaSession[]>([]);
	const [withBestPractices, setWithBestPractices] = useState<boolean>(true);

	// TODO: rename to filters based on data or something
	const [tableFilters, setTableFilters] = useState<IFilterOptions>();
	const [summaryPaneData, setSummaryPaneData] = useState<SummaryItem[]>([]);
	const [sortByValues, setSortByValues] = useState<TableSortOption>(defaultSort);

	const initialValues = {
		text: { searchValue: tableState?.filters?.searchValue },
		select: omit(tableState?.filters, 'searchValue')
	};

	useSkipLinks(!isLoading);

	const updateFilters = useCallback(
		(appliedFilters: IFilterValues): void => {
			const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
			const filteredSessions = getFilteredMFASessions(allMfaSessions, flatFilters);

			setTableData(filteredSessions);
			tableStateContext.onFilterChange(MFA_SESSIONS_TABLE_ID, flatFilters);
		},
		[allMfaSessions, tableStateContext]
	);

	const loadData = useCallback(async (): Promise<void> => {
		try {
			// 1. Fetch MFA Sessions Data
			const resWithoutExtraData = await getMfaSessions({
				tenantId: tenant.id,
				productId
			});
			const res = assertSessionExtraData(resWithoutExtraData);
			const mfaSessions: MfaSession[] = buildMFASessionsList(res);

			setAllMfaSessions(mfaSessions);

			if (mfaSessions.length > 0) {
				const filters: IFilterOptions = buildTableFilters(mfaSessions);
				setTableFilters(filters);

				const filteredSessions = getFilteredMFASessions(mfaSessions, tableState?.filters);
				setTableData(filteredSessions);
			}
		} catch (err) {
			Logger.error('failed to load MFA dashboard data', err);
		}
	}, [tenant.id, productId, tableState?.filters]);

	useEffect(() => {
		loadData().finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useInterval(() => {
		loadData();
	}, REFRESH_TABLE_INTERVAL);

	useEffect(() => {
		const lastMonthData: SummaryItem[] = buildMFASummaryPaneData(tableData);
		setSummaryPaneData(lastMonthData);
	}, [tableData]);

	if (isLoading) {
		return <EvSpinner />;
	}

	if (allMfaSessions?.length === 0) {
		return (
			<LoadErrorMsgLayout
				mainTitle={NO_SYNCED_SESSIONS_TITLE}
				secondaryMsg={NO_SYNCED_SESSIONS_MSG}
				icon={CloudStatusIcon}
				className="dashboard-no-sessions-msg"
			>
				<ContactUsButton product={ProductType.MOBILE_FLOW_ANALYZER} />
			</LoadErrorMsgLayout>
		);
	}

	return (
		<div className="mfa-sessions-page">
			<EvTitle titleText="Mobile report" headingLevel={1} />

			<EvSection
				ariaLabel="MFA All Session Table"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				ariaLive="polite"
				skipLinkId="main-section"
			>
				<div className="filter-line">
					{tableFilters && (
						<EvFilters
							className="scan-data-filter"
							filterDefinitions={MfaSessionsPageFilterDefinitions}
							initialValues={initialValues}
							filterOptions={{
								os: tableFilters.os,
								apps: tableFilters.apps,
								versions: tableFilters.versions
							}}
							isFilterOptionsLoaded={!isLoading}
							onApply={updateFilters}
							onReset={updateFilters}
						/>
					)}
					<BestPracticesButton
						onChange={() =>
							setWithBestPractices((prevWithBestPracticesValue) => !prevWithBestPracticesValue)
						}
						withBestPractices={withBestPractices}
					/>
				</div>

				<EvSection ariaLabel="MFA last month usage" className="last-month-usage-section">
					<EvTitle
						className="last-month-usage-title"
						titleText="Last month usage"
						id="Last month usage title"
						// eslint-disable-next-line react/jsx-no-useless-fragment
						MoreInfoContent={<>{LAST_MONTH_USAGE_TOOLTIP}</>}
					/>
					<SummaryPane
						items={summaryPaneData}
						className="base-summary-pane"
						ariaLabel="MFA Sessions monthly Usage summary"
					/>
				</EvSection>

				<div className="table-title-and-actions-container">
					<EvTitle
						titleText="All sessions"
						className="all-session-table-title"
						id="MFA All Sessions"
					/>
					<DownloadCSV
						csvFileName={`${tenant.id}-${productId}.csv`}
						reportHeaders={[
							...addHeaderColumns(
								[...createHeadersOutOfTableColumns(getMfaSessionsColumns(withBestPractices))],
								[{ label: 'Session Link', key: 'sessionLink' }]
							)
						]}
						data={tableData}
						sortByValue={sortByValues}
						formatDateColumns={formatDateColumns}
					/>
				</div>

				<PlatformTable
					tableId={MFA_SESSIONS_TABLE_ID}
					tableData={tableData}
					totalCount={tableData.length}
					className="mfa-sessions-table"
					persistTableState
					columns={getMfaSessionsColumns(withBestPractices)}
					onSortCB={setSortByValues}
					options={{
						keyField: 'sessionId',
						title: 'MFA Sessions',
						caption: 'MFA Sessions table',
						pagination: true,
						dataType: 'sessions',
						defaultSorted: [sortByValues],
						// TODO: remove once everything done remotely
						localProps: {
							defaultPage: 1,
							defaultSizePerPage: 10
						}
					}}
				/>
			</EvSection>
		</div>
	);
};
