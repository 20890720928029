import React from 'react';
import { useHistory } from 'react-router';

import { EvLink, EvLinkTypes } from '@evinced-private/ui-common';

import { NotGroupedComponent } from 'src/common/consts/NotGroupedComponent';
import { usePageScrollPositionState } from 'src/common/providers/PageScrollPositionProvider';

interface IComponentCellFormatterProps {
	componentId: string;
	tableId: string;
	url: string;
	onClick?: () => void;
}

export const ComponentCellFormatter: React.FC<IComponentCellFormatterProps> = ({
	componentId,
	tableId,
	url,
	onClick
}) => {
	const history = useHistory();
	const { setLastClickedElementScrollPosition } = usePageScrollPositionState();

	const formattedID = componentId.split(' ').join('-');
	const ariaLabel =
		componentId === NotGroupedComponent.DISPLAY_NAME ? componentId : `Component ${componentId}`;

	return (
		<EvLink
			id={`component-${formattedID}`}
			url={url}
			ariaLabel={ariaLabel}
			linkText={componentId}
			type={EvLinkTypes.DARK}
			onClick={() => {
				onClick();
				setLastClickedElementScrollPosition(tableId, `[id=component-${componentId}]`);
				history.push(url);
			}}
		/>
	);
};
