import React, { useState } from 'react';

import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';

import { PlatformLabel } from 'src/common/types/IssueDetailsType';

import { LabelsBlock } from '../labels-block/LabelsBlock';
import { PlatformPopup } from '../platform-popup/PlatformPopup';

import './LabelsPopup.scss';

interface LabelsPopupProp {
	name: string;
	labels: PlatformLabel[];
	title: string;
}

export const LabelsPopup: React.FC<LabelsPopupProp> = ({ name, labels, title }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<div className="labels-popup">
			<EvButton
				title={title}
				className="view-all-button"
				type={BUTTON_TYPES.LINK}
				onClick={() => setIsModalOpen(true)}
			>
				View All
			</EvButton>
			<PlatformPopup
				popupWidth={600}
				isControlled
				isBgTransparent
				onClose={() => setIsModalOpen(false)}
				title={`${name} Labels`}
				isOpen={isModalOpen}
			>
				<LabelsBlock labels={labels} />
			</PlatformPopup>
		</div>
	);
};
