import { calcPercentageDifference } from 'src/common/helpers/MathHelper';
import { SummaryItem } from 'src/common/interfaces/SummaryItem';

import {
	IGetMSdkSessionsResponse,
	IMSDKLastMonthSummary,
	MSdkTest
} from '../../mobile-common/types/MobileSdkTypes';

// TODO: move to a Dashboard global consts file
const MILIS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_TO_LOOK_BACK = 30;

// TODO: refactor to be Generic function for both MFA Sessions and MSDK Tests
const calcMSDKSummaryDataOverTwoMonths = (tests: MSdkTest[]): IMSDKLastMonthSummary => {
	let totalTestsThisMonth = 0;
	let totalScansThisMonth = 0;
	let totalCriticalIssuesThisMonth = 0;

	let totalTestsPrevMonth = 0;
	let totalScansPrevMonth = 0;
	let totalCriticalIssuesPrevMonth = 0;

	const today = new Date();
	const daysAgoInMilis = DAYS_TO_LOOK_BACK * MILIS_PER_DAY;
	const thirtyDaysAgo = new Date(today.getTime() - daysAgoInMilis);
	const sixtyDaysAgo = new Date(thirtyDaysAgo.getTime() - daysAgoInMilis);

	tests.forEach((test: MSdkTest) => {
		const testCreatedDate = new Date(test.createdAt);

		// If session is from the past month
		if (testCreatedDate >= thirtyDaysAgo && testCreatedDate <= today) {
			totalTestsThisMonth++;
			totalScansThisMonth += test.totalScans;
			totalCriticalIssuesThisMonth += test.criticalIssues;
		}

		// If session is from 2 months ago
		if (testCreatedDate >= sixtyDaysAgo && testCreatedDate < thirtyDaysAgo) {
			totalTestsPrevMonth++;
			totalScansPrevMonth += test.totalScans;
			totalCriticalIssuesPrevMonth += test.criticalIssues;
		}
	});

	const totalTestsLift: number = calcPercentageDifference(totalTestsThisMonth, totalTestsPrevMonth);

	const totalScansLift: number = calcPercentageDifference(totalScansThisMonth, totalScansPrevMonth);

	const totalCriticalIssuesLift: number = calcPercentageDifference(
		totalCriticalIssuesThisMonth,
		totalCriticalIssuesPrevMonth
	);

	return {
		totalTests: totalTestsThisMonth,
		totalScans: totalScansThisMonth,
		totalCriticalIssues: totalCriticalIssuesThisMonth,
		totalTestsLift,
		totalScansLift,
		totalCriticalIssuesLift
	};
};

export const buildMSDKSummaryPaneData = (msdkTests: MSdkTest[]): SummaryItem[] => {
	const msdkSummaryData: IMSDKLastMonthSummary = calcMSDKSummaryDataOverTwoMonths(msdkTests);
	return [
		{
			value: msdkSummaryData.totalTests,
			label: 'Total tests',
			lift: msdkSummaryData.totalTestsLift
		},
		{
			value: msdkSummaryData.totalScans,
			label: 'Total scans',
			lift: msdkSummaryData.totalScansLift
		},
		{
			value: msdkSummaryData.totalCriticalIssues,
			label: 'Critical issues',
			lift: msdkSummaryData.totalCriticalIssuesLift
		}
	];
};

export const buildMSdkTestList = (
	msdkTestsFromEndpoint: IGetMSdkSessionsResponse[]
): MSdkTest[] => {
	return msdkTestsFromEndpoint.map((session) => session.data);
};
