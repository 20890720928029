// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-sessions-page {
  height: 100%;
  padding: 30px;
}
.wfa-sessions-page .page-heading {
  margin-bottom: 20px;
}
.wfa-sessions-page .dashboard-section {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/apps/wfa/pages/sessions/WfaSessionsPage.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;AACD;AACC;EACC,mBAAA;AACF;AAEC;EACC,mBAAA;AAAF","sourcesContent":[".wfa-sessions-page {\n\theight: 100%;\n\tpadding: 30px;\n\n\t.page-heading {\n\t\tmargin-bottom: 20px;\n\t}\n\n\t.dashboard-section {\n\t\tmargin-bottom: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
