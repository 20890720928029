import { returnNonNull } from 'src/common/helpers/ArraysHelper';

import {
	IWfaSessionComponent,
	TWfaSessionComponentsResult
} from '../../../WfaDashboardServiceTypes';
import { WfaComponentsQuery } from '../WfaComponents.generated';

export const transformSessionComponentsResults = (
	rawResults: WfaComponentsQuery
): TWfaSessionComponentsResult => {
	const rows: IWfaSessionComponent[] =
		rawResults.wfa_session_components_runs?.filter(returnNonNull).map((component) => ({
			id: component.component_id,
			totalIssues: component.total_issues,
			pagesImpacted: component.total_pages,
			types: component.type_names
		})) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.component_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.component_id_count ?? 0;
	}

	return {
		rows,
		total,
		unfilteredTotal
	};
};
