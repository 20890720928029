import React, { FC } from 'react';

import { SessionViews } from 'src/common/types/WfaSessionViews';

import { WfaSessionAllIssues } from './all-issues/WfaSessionAllIssues';
import { WfaSessionComponents } from './components/WfaSessionComponents';
import { WfaSessionOverview } from './overview/WfaSessionOverview';

interface IWfaSingleSessionTabContentProps {
	viewId: SessionViews;
	sessionId: string;
}
export const WfaSingleSessionTabContent: FC<IWfaSingleSessionTabContentProps> = ({
	viewId,
	sessionId
}) => {
	switch (viewId) {
		case SessionViews.OVERVIEW: {
			return <WfaSessionOverview sessionId={sessionId} />;
		}

		case SessionViews.COMPONENTS: {
			return <WfaSessionComponents sessionId={sessionId} />;
		}

		case SessionViews.ALL_ISSUES: {
			return <WfaSessionAllIssues sessionId={sessionId} />;
		}
		default:
			return null;
	}
};
