import React, { FC, useCallback } from 'react';

import { DetailsPopup } from 'src/common/components/details-popup/DetailsPopup';

import { ShowDetailsCellFormatter } from './ShowDetailsCellFormatter';

interface IShowIssueDetailsCellFormatter {
	renderDetailsBlock: (setError: (msg: string) => void) => JSX.Element;
	infoTitle: string;
	issueType?: string;
}

export const ShowIssueDetailsCellFormatter: FC<IShowIssueDetailsCellFormatter> = ({
	renderDetailsBlock,
	issueType,
	infoTitle
}) => {
	const renderPopup = useCallback(
		(closeModal: () => void) => (
			<DetailsPopup
				renderDetailsBlock={renderDetailsBlock}
				issueType={issueType}
				onClose={closeModal}
			/>
		),
		[issueType, renderDetailsBlock]
	);

	return (
		<ShowDetailsCellFormatter
			infoTitle={infoTitle}
			renderPopup={renderPopup}
			ariaLabel="Show Issue Details"
		/>
	);
};
