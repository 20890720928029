// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-session-all-issues-view {
  margin-top: 15px;
}
.wfa-session-all-issues-view .wfa-all-issues-table-url-column .url-cell-formatter {
  width: auto !important;
}
.wfa-session-all-issues-view .wfa-all-issues-table-url-column .url-cell-formatter.show-full-url {
  max-width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/apps/wfa/pages/single-session/views/all-issues/WfaSessionAllIssues.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AAEE;EACC,sBAAA;AAAH;AACG;EACC,gBAAA;AACJ","sourcesContent":[".wfa-session-all-issues-view {\n\tmargin-top: 15px;\n\n\t.wfa-all-issues-table-url-column {\n\t\t.url-cell-formatter {\n\t\t\twidth: auto !important;\n\t\t\t&.show-full-url {\n\t\t\t\tmax-width: 240px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
