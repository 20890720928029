import { NotGroupedComponent } from 'src/common/consts/NotGroupedComponent';
import { returnNonNull } from 'src/common/helpers/ArraysHelper';

import { IWfaSessionIssue, TWfaSessionAllIssuesResult } from '../../../WfaDashboardServiceTypes';
import { WfaSessionIssuesQuery } from '../WfaSessionIssues.generated';

export const transformSessionIssuesResults = (
	rawResults: WfaSessionIssuesQuery
): TWfaSessionAllIssuesResult => {
	const rows: IWfaSessionIssue[] =
		rawResults.issues?.filter(returnNonNull).map(
			(issue): IWfaSessionIssue => ({
				id: issue.issue_id,
				sessionName: issue.session_name,
				sessionId: issue.run_id,
				runTime: issue.run_time,
				type: issue.type_name,
				severity: issue.severity_name,
				components: issue.elements__component_id?.map((componentId: string) => {
					return componentId === NotGroupedComponent.ID
						? NotGroupedComponent.DISPLAY_NAME
						: componentId;
				}) ?? [NotGroupedComponent.DISPLAY_NAME],
				url: issue.url,
				pageTitle: issue.page_title,
				selectors: issue.elements__selector
			})
		) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.issue_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.issue_id_count ?? 0;
	}
	return {
		rows,
		total,
		unfilteredTotal
	};
};
