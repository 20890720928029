import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ProductType } from 'src/common/interfaces/Tenant';
import { PLATFORM_ISSUE_DETAILS } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { getPlatformIssueDetails } from '../services/issue-details/CommonIssueDetailsService';
import { PlatformIssueDetails } from '../types/IssueDetailsType';

interface IUseIssueDetailsQueryParams {
	issueId: string;
	runId: string;
	product: ProductType;
}
export const useIssueDetailsQuery = ({
	issueId,
	runId,
	product
}: IUseIssueDetailsQueryParams): UseQueryResult<PlatformIssueDetails> => {
	const issueDetailsQuery = useQuery(
		[PLATFORM_ISSUE_DETAILS, runId, issueId],
		(): Promise<PlatformIssueDetails> => getPlatformIssueDetails(issueId, runId, product)
	);

	return issueDetailsQuery;
};
