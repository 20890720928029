import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { EvIcon } from '@evinced-private/ui-common';

import { EvLinkLocal } from 'src/common/components/ev-link-local/EvLinkLocal';
import NotFoundIcon from 'src/common/components/icons/NotFoundIcon.svg';

import { REDIRECT_TO_FROM_404_PAGE_QUERY_PARAM } from '../../helpers/QueryParamsHelper';
import { RoutesHelper } from '../../helpers/RoutesHelper';

import './NotFound404Page.scss';

interface INotFound404PagePageProps {
	redirectTo?: string;
}
export const NotFound404Page: FC<INotFound404PagePageProps> = ({
	redirectTo = RoutesHelper.getHomepagePath()
}) => {
	const history = useHistory();

	const search = history?.location?.search;
	if (search) {
		const queryParams = new URLSearchParams(search);
		const redirectToFromUrl = queryParams.get(REDIRECT_TO_FROM_404_PAGE_QUERY_PARAM);
		if (redirectToFromUrl) {
			redirectTo = redirectToFromUrl;
		}
	}

	return (
		<div className="error-404">
			<div className="error-404-content">
				<EvIcon icon={NotFoundIcon} />
				<div className="text-messge">
					<p>Sorry, we couldn&apos;t find what you were looking for...</p>
					<EvLinkLocal url={redirectTo} className="back" linkText="Back to Dashboard page" />
				</div>
			</div>
		</div>
	);
};
