import { ProductType } from 'src/common/interfaces/Tenant';
import { buildIssueDetailsVariables } from 'src/common/services/queries/issue-details/helpers/buildVariables';
import { transformIssueDetailsResults } from 'src/common/services/queries/issue-details/helpers/transformResults';
import { IssueDetailsQuery } from 'src/common/services/queries/issue-details/IssueDetails.generated';
import { IssueDetails } from 'src/common/services/queries/issue-details/IssueDetails.gql';

import { PlatformIssueDetails } from '../../types/IssueDetailsType';
import { gqlClient } from '../api/GraphQLRequestApi';
import { Logger } from '../Logger';

export const getPlatformIssueDetails = async (
	issueId: string,
	runId: string,
	product: ProductType
): Promise<PlatformIssueDetails> => {
	try {
		const variables = buildIssueDetailsVariables(issueId, runId);

		const result = await gqlClient.request<IssueDetailsQuery>(IssueDetails, variables);

		const transformedResult = transformIssueDetailsResults(result, product);
		if (!transformedResult) {
			throw new Error(`Issue ${runId} > ${issueId} not found.`);
		}
		return transformedResult;
	} catch (e) {
		Logger.error('CommonIssueDetailsService -> getPlatformIssueDetails failed with an error:', e);
		throw e;
	}
};
