import React from 'react';

import HandsIcon from 'src/common/components/icons/HandsIcon.svg';

import {
	WEB_SDK_NO_ISSUES_MSG,
	WEB_SDK_NO_ISSUES_TITLE
} from '../../../../common/pages/dashboards-container/errorMessages';

import { WebSdkError } from './WebSdkError';

interface WebSDKErrorProps {
	isNoData?: boolean;
}
export const WebSdkIssuesPageError: React.FC<WebSDKErrorProps> = ({ isNoData }) => {
	if (isNoData) {
		return (
			<WebSdkError
				hideButton
				mainTitle={WEB_SDK_NO_ISSUES_TITLE}
				secondaryMsg={WEB_SDK_NO_ISSUES_MSG}
				icon={HandsIcon}
			/>
		);
	}

	return <WebSdkError />;
};
