// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-popup .spinner-wrapper {
  width: 500px;
  height: 500px;
}
.details-popup .ev-popup-container {
  max-width: 1050px;
}
.details-popup .ev-popup-container .close-btn-and-header-wrapper {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/common/components/details-popup/DetailsPopup.scss"],"names":[],"mappings":"AAEC;EACC,YAAA;EACA,aAAA;AADF;AAGC;EAEC,iBAAA;AAFF;AAGE;EACC,kBAAA;AADH","sourcesContent":["// This file was copied from site scanner. Planned to move it to ui-common\n.details-popup {\n\t.spinner-wrapper {\n\t\twidth: 500px;\n\t\theight: 500px;\n\t}\n\t.ev-popup-container {\n\t\t// should be 1050px for consistency with other products\n\t\tmax-width: 1050px;\n\t\t.close-btn-and-header-wrapper {\n\t\t\tposition: relative;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
