// this file is copied from websdks until we make an issues service
import { uniq } from 'lodash-es';

import { returnNonNull } from 'src/common/helpers/ArraysHelper';
import { orderByMethod } from 'src/common/helpers/DataFilterHelper';
import { formatFilterOptionsForEvSelect } from 'src/common/helpers/FormatHelper';

import { TWfaSessionIssuesFilterOptions } from '../../../WfaDashboardServiceTypes';
import { WfaSessionIssuesFilterOptionsQuery } from '../WfaSessionIssuesFilterOptions.generated';

export const transformWfaSessionIssuesFilterOptionsResults = (
	rawResults: WfaSessionIssuesFilterOptionsQuery
): TWfaSessionIssuesFilterOptions => {
	const {
		url_group_uniq_array: urlResults,
		severity_name_group_uniq_array: severityResults,
		type_name_group_uniq_array: typeResults
	} = rawResults?.filter_groups?.[0] ?? {};

	const issues = rawResults?.issues?.filter(returnNonNull) ?? [];
	const levelResults: string[] = issues.reduce(
		(result, issue) => [...result, ...issue.conformance],
		[]
	);

	const results = {
		types: formatFilterOptionsForEvSelect(orderByMethod(typeResults)),
		severities: formatFilterOptionsForEvSelect(orderByMethod(severityResults, 'severity')),
		urls: formatFilterOptionsForEvSelect(orderByMethod(urlResults), '', false),
		levels: formatFilterOptionsForEvSelect(orderByMethod(uniq(levelResults)))
	};

	return results;
};
