import { Location } from 'history';

import { RoutesHelper } from 'src/common/helpers/RoutesHelper';

import { IEvBreadcrumb } from '../../components/ev-breadcrumbs/EvBreadcrumbs';
import { MSDK_REPORT_ID_PARAM, MSDK_TEST_NAME_PARAM } from '../../helpers/QueryParamsHelper';

const MAIN_PAGE_NAV_LABEL = 'All Tests';

const getMsdkTestNameParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get(MSDK_TEST_NAME_PARAM);
};

export const getMsdkReportIdParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get(MSDK_REPORT_ID_PARAM);
};

export const getMsdkDashboardBreadcrumbsRoutes = (location: Location<object>): IEvBreadcrumb[] => {
	return [
		{
			id: MAIN_PAGE_NAV_LABEL,
			url: RoutesHelper.getMobileSdkDashboardPath(),
			title: MAIN_PAGE_NAV_LABEL
		},
		{
			id: getMsdkReportIdParam(location),
			url: '',
			title: getMsdkTestNameParam(location)
		}
	];
};
