import { buildExtendedLabels } from 'src/common/helpers/GQLRequestsHelper/QueryResultsTransformHelper';
import { ProductType } from 'src/common/interfaces/Tenant';

import { IWebSdkTestDetails } from '../../../../types/WebSdkDashboardServiceTypes';
import { TestDetailsQuery } from '../TestDetails.generated';

export const transformTestDetailsResults = (
	rawResults: TestDetailsQuery
): IWebSdkTestDetails | null => {
	if (!rawResults.wsdk_test_runs?.length) {
		return null;
	}

	const test = rawResults.wsdk_test_runs[0];
	if (!test) {
		return null;
	}

	const labels = buildExtendedLabels({
		product: ProductType.WEB_SDK,
		flow: test.flows?.[0],
		environment: test.environments?.[0],
		runTime: test.run_time,
		properties: test.property_ids,
		customFields: test.custom_fields,
		errorMessage: `Missing data in TestDetails query for test run ${test.run_id}: `
	});

	const results: IWebSdkTestDetails = {
		runId: test.run_id,
		totalIssues: test.total_issues,
		runTime: test.run_time,
		name: test.test_name,
		environments: test.environments,
		flows: test.flows,
		testPath: test.test_path,
		propertyIds: test.property_ids,
		labels
	};
	return results;
};
