import React, { FC, useCallback, useLayoutEffect } from 'react';

import {
	EvTable,
	IEvTableProps,
	SORT_ORDER,
	TableChangeState,
	TableChangeType,
	TableSortOption
} from '@evinced-private/ui-common';

import {
	ITableStateContext,
	useTableState
} from 'src/common/providers/dashboard/TableStateProvider';
import { usePageScrollPositionState } from 'src/common/providers/PageScrollPositionProvider';

type IPlatformTableProps = Omit<IEvTableProps, 'rootApplicationSelector'> & {
	tableId: string;
	persistTableState?: boolean;
	totalPaginationableResults?: number;
	isTableLoading?: boolean;
	onSortCB?: (sortDetails: TableSortOption) => void;
};

export const PlatformTable: FC<IPlatformTableProps> = ({
	tableId,
	persistTableState = true,
	totalPaginationableResults,
	isTableLoading,
	onSortCB,
	...props
}: IPlatformTableProps) => {
	const tableStateContext: ITableStateContext = useTableState();
	const tableState = tableStateContext.getTableStateById(tableId);

	const { scrollToSavedPosition, pagesScrollPositionState } = usePageScrollPositionState();
	const { options } = props;

	useLayoutEffect(() => {
		const pagesPositionSettings = pagesScrollPositionState?.[tableId];
		if (pagesPositionSettings) {
			const { elementSelector } = pagesPositionSettings;
			if (elementSelector) {
				scrollToSavedPosition(tableId, !isTableLoading);
			}
		}
	}, [tableId, pagesScrollPositionState, scrollToSavedPosition, isTableLoading]);

	const onPageChange = useCallback(
		(page: number, sizePerPage: number): void => {
			tableStateContext.onTablePageOrPageSizeChange(tableId, { page, sizePerPage });
		},
		[tableId, tableStateContext]
	);

	const onSizePerPageChange = useCallback(
		(sizePerPage: number, page: number): void => {
			tableStateContext.onTablePageOrPageSizeChange(tableId, { page, sizePerPage });
		},
		[tableId, tableStateContext]
	);

	const onSort = useCallback(
		(sortDetails: TableSortOption[]): void => {
			if (onSortCB) {
				onSortCB(sortDetails[0]);
			}
			tableStateContext.onTableSort(tableId, sortDetails);
		},
		[tableId, tableStateContext, onSortCB]
	);

	const { page, sizePerPage } = tableState.paginationInfo;
	const sort = tableState.tableSort || options.defaultSorted;
	if (options.remote) {
		options.remoteProps = {
			onTableChange: (
				type: TableChangeType,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ page, sizePerPage, sortField, sortOrder }: TableChangeState<any>
			): void => {
				if (type === 'sort') {
					// the first time a table is loaded the onTableChange
					// function is called with the same sort
					// we want to ignore its changing on the page size
					const currentSort: TableSortOption = sort?.[0] || { order: null, dataField: null };
					if (currentSort.order === sortOrder && currentSort.dataField === sortField) {
						return;
					}

					// TODO: do i need to reset pagination to page 1 on sort (local/remote)?
					tableStateContext.onTableSort(tableId, [
						{ dataField: sortField, order: sortOrder as SORT_ORDER }
					]);
				} else if (type === 'pagination') {
					tableStateContext.onTablePageOrPageSizeChange(tableId, { page, sizePerPage });
				}
			},
			page,
			sizePerPage,
			sort,
			totalSize: totalPaginationableResults // todo: add a totalSize prop in state
		};
		options.isLoading = isTableLoading;
	} else if (persistTableState) {
		if (options?.pagination) {
			options.localProps = {
				defaultPage: page,
				defaultSizePerPage: sizePerPage,
				defaultSort: sort,
				onSort,
				onPageChange,
				onSizePerPageChange
			};
		}
	}

	return <EvTable key={tableId} {...props} rootApplicationSelector=".app" />;
};
