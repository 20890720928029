import React, { FC } from 'react';

import { EvLink, EvLinkTypes } from '@evinced-private/ui-common';

interface IEvChartTableLinkProps {
	value: string;
	onClick: () => void;
	ariaLabel?: string;
}

export const EvChartTableLink: FC<IEvChartTableLinkProps> = ({ onClick, value, ariaLabel }) => {
	return (
		<EvLink
			url="#"
			ariaLabel={ariaLabel || value}
			linkText={value}
			type={EvLinkTypes.DARK}
			onClick={onClick}
		/>
	);
};
