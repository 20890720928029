import React, { FC, useCallback, useEffect, useState } from 'react';

import { EvSpinner } from '@evinced-private/ui-common';

import { ContactUsButton } from 'src/common/components/contact-us/ContactUsButton';
import ErrorLoadPageIcon from 'src/common/components/icons/ErrorLoadPageIcon.svg';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { TableStateProvider } from 'src/common/providers/dashboard/TableStateProvider';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { Logger } from 'src/common/services/Logger';

import { getProductDashboardAccessPermission } from '../../services/CommonDashboardService';
import { LoadErrorMsgLayout } from '../property/LoadErrorMsgLayout';

import { ACCESS_DENIED_MSG, ACCESS_DENIED_TITLE } from './errorMessages';

import './DashboardContainer.scss';

interface IDashboardContainerProps {
	product: ProductType;
	children: React.ReactNode;
}

export const DashboardContainer: FC<IDashboardContainerProps> = ({ product, children }) => {
	const { tenant, getProductIdByType } = useUserTenant();
	const { getToggle, configuration } = useConfiguration();
	const productId: string = getProductIdByType(product);

	const [isDashboardEnabled, setIsDashboardEnabled] = useState<boolean>(null);

	const initDashboard = useCallback((): void => {
		try {
			const isPlatformEnabledForCurrentProduct = getProductDashboardAccessPermission(
				tenant,
				getToggle,
				product
			);
			setIsDashboardEnabled(isPlatformEnabledForCurrentProduct);
		} catch (err) {
			Logger.error('Failed to get product features', err);
		}
	}, [tenant, getToggle, product]);

	useEffect(() => {
		if (!configuration) {
			// because permissions depend on configuration from the server, we need to wait for it
			return;
		}
		if (productId) {
			initDashboard();
		} else {
			setIsDashboardEnabled(false);
		}
	}, [productId, initDashboard, product, configuration]);

	if (isDashboardEnabled === null) {
		return <EvSpinner />;
	}

	return isDashboardEnabled ? (
		<TableStateProvider>{children}</TableStateProvider>
	) : (
		<LoadErrorMsgLayout
			mainTitle={ACCESS_DENIED_TITLE}
			secondaryMsg={ACCESS_DENIED_MSG}
			icon={ErrorLoadPageIcon}
			className="mfa-dashboard-access-denied-msg"
		>
			<ContactUsButton product={product} />
		</LoadErrorMsgLayout>
	);
};
