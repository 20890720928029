import React, { FC, useEffect, useState } from 'react';

import { BUTTON_TYPES, EvSwitch } from '@evinced-private/ui-common';

import {
	DevelopmentToggles,
	getSingleToggle,
	removeToggle,
	setToggle
} from 'src/common/services/TogglesService';

import { PlatformPopup } from '../platform-popup/PlatformPopup';

import './DevTogglesPopup.scss';

const TOGGLES_DETAILS = [
	{ name: DevelopmentToggles.CAN_VIEW_DASHBOARDS, label: 'Can access all dashboards views' },
	{ name: DevelopmentToggles.CAN_ACCESS_MFA_DASHBOARD, label: 'Can access MFA dashboard view' },
	{
		name: DevelopmentToggles.CAN_ACCESS_MOBILE_SDK_DASHBOARD,
		label: 'Can access Mobile SDK dashboard view'
	},
	{
		name: DevelopmentToggles.CAN_ACCESS_WEB_SDK_DASHBOARD,
		label: 'Can access Web SDK dashboard view'
	},
	{
		name: DevelopmentToggles.CAN_ACCESS_WFA_DASHBOARD,
		label: 'Can access WFA dashboard view'
	},
	{ name: DevelopmentToggles.ENABLE_AZURE_INTEGRATION, label: 'Enable Azure Integration' },
	{ name: DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES, label: 'Enable Web SDK Properties' }
];

interface IDevTogglesPopup {
	closePopup: () => void;
	isOpen: boolean;
}

export const DevTogglesPopup: FC<IDevTogglesPopup> = ({ closePopup, isOpen }: IDevTogglesPopup) => {
	const [toggles, setToggles] = useState({});

	const setTogglesState = (): void => {
		const newState = {};
		TOGGLES_DETAILS.forEach((toggle) => {
			const state = getSingleToggle(toggle.name, false);
			newState[toggle.name] = state;
		});
		setToggles(newState);
	};

	useEffect(() => {
		setTogglesState();
	}, []);

	const onChange = (type) => {
		return (state) => {
			setToggles({ ...toggles, [type]: state });
		};
	};

	const saveChanges = (): void => {
		TOGGLES_DETAILS.forEach((toggle) => {
			if (toggles[toggle.name]) {
				setToggle(toggle.name, 'true');
			} else {
				removeToggle(toggle.name);
			}
		});
	};

	const onClose = (): void => {
		setTogglesState();
		closePopup();
	};

	const buttons = [
		{
			title: 'Cancel',
			onClick: onClose,
			type: BUTTON_TYPES.SECONDARY
		},
		{
			title: 'Save',
			onClick: () => {
				saveChanges();
				closePopup();
			}
		}
	];
	return (
		<PlatformPopup
			title="Dev Toggles"
			isControlled
			isOpen={isOpen}
			onClose={onClose}
			buttons={buttons}
			isCentered={true}
			className="dev-toggles-popup"
		>
			<div className="popup-form">
				{TOGGLES_DETAILS.map((toggle) => {
					return (
						<div className="popup-form-row" key={toggle.name}>
							<div className="switchWrapper ev-switch-comp">
								<EvSwitch
									className="toggle-switch"
									label={toggle.label}
									checked={toggles[toggle.name]}
									onChange={onChange(toggle.name)}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</PlatformPopup>
	);
};
