import React, { FC, useCallback, useEffect } from 'react';

import {
	BUTTON_TYPES,
	COPY_SELECTOR_BUTTON_DAID,
	EvButton,
	EvIssueDetails
} from '@evinced-private/ui-common';

import { CopyToClipboard } from 'src/common/components/copy-to-clipboard/CopyToClipboard';
import { mapPlatformIssueDetailsToReport } from 'src/common/components/details-block/DetailsBlockHelper';
import { TicketsSystems } from 'src/common/components/tickets-systems/TicketsSystems';
import { COPY_ISSUE_LINK_DAID, OPEN_IN_NEW_TAB_DAID } from 'src/common/consts/data-automation-ids';
import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { getBaseURL } from 'src/common/helpers/UrlNormalizingHelper';
import { useIssueDetailsQuery } from 'src/common/hooks/useIssueDetailsQuery';
import { ProductType } from 'src/common/interfaces/Tenant';
import { Logger } from 'src/common/services/Logger';
import { screenshotService } from 'src/common/services/ScreenshotService';

interface IWfaDetailsBlockProps {
	runId: string;
	issueId: string;
	setError?: (error: unknown) => void;
	isFullPageMode?: boolean;
}

export const WfaDetailsBlock: FC<IWfaDetailsBlockProps> = ({
	runId,
	issueId,
	setError,
	isFullPageMode
}) => {
	const issueDetailsQuery = useIssueDetailsQuery({
		issueId,
		runId,
		product: ProductType.WEB_FLOW_ANALYZER
	});

	useEffect(() => {
		if (issueDetailsQuery.isError) {
			setError?.(issueDetailsQuery.error);
		}
	}, [issueDetailsQuery.isError, issueDetailsQuery.error, setError]);

	const issuePageUrl = RoutesHelper.getWfaIssueInfoPath({
		runId,
		issueId
	});

	const urlToCopy = `${getBaseURL()}${issuePageUrl}`;

	const issue = issueDetailsQuery.data;
	const {
		type,
		severity,
		elements,
		level,
		summary,
		cause,
		howToFix,
		effect,
		knowledgeBaseLink,
		tags,
		note,
		screenshotDetails
	} = issue || {};
	const { pageUrl, pageTitle } = elements?.[0] ?? {};

	const openIssueInNewTab = useCallback((): void => {
		window.open(issuePageUrl, '_blank');
	}, [issuePageUrl]);

	const renderCopyToClipboardButton = useCallback(
		(textToCopy: string) => (
			<CopyToClipboard
				renderJustIcon
				textToCopy={textToCopy}
				dataAutomationId={COPY_SELECTOR_BUTTON_DAID}
				iconTitle="Copy Selector"
			/>
		),
		[]
	);

	const topRowActions = (
		<div className="action-buttons">
			<CopyToClipboard
				textToCopy={urlToCopy}
				buttonType={BUTTON_TYPES.SECONDARY}
				dataAutomationId={COPY_ISSUE_LINK_DAID}
			/>
			{!isFullPageMode && (
				<EvButton
					type={BUTTON_TYPES.SECONDARY}
					title="Open in a New Tab"
					onClick={openIssueInNewTab}
					dataAutomationId={OPEN_IN_NEW_TAB_DAID}
				>
					Open in a New Tab
				</EvButton>
			)}
			{!issueDetailsQuery.isLoading && (
				<TicketsSystems issue={mapPlatformIssueDetailsToReport(issue)} />
			)}
		</div>
	);
	return (
		<EvIssueDetails
			issue={{
				type,
				severity,
				elements,
				level,
				summary,
				cause,
				note,
				howToFix,
				effect,
				knowledgeBaseLink,
				tags,
				pageUrl,
				pageTitle,
				screenshotDetails
			}}
			showNote
			isLoading={issueDetailsQuery.isLoading}
			isFullPageMode={isFullPageMode}
			topRowActions={topRowActions}
			screenshotService={screenshotService}
			logger={Logger}
			copyToClipboardButton={renderCopyToClipboardButton}
		/>
	);
};
