import React from 'react';

import { EvButton } from '@evinced-private/ui-common';

import CloudStatusIcon from 'src/common/components/icons/CloudStatusIcon.svg';

import { WEB_SDK_DOCUMENTATION_LINK } from '../../../../common/pages/dashboards-container/dashboard_consts';
import {
	WEB_SDK_NO_SYNCED_SESSIONS_MSG,
	WEB_SDK_NO_SYNCED_SESSIONS_TITLE
} from '../../../../common/pages/dashboards-container/errorMessages';

import { WebSdkError } from './WebSdkError';

interface WebSDKErrorProps {
	isNoData?: boolean;
}
export const WebSdkTestsPageError: React.FC<WebSDKErrorProps> = ({ isNoData }) => {
	if (isNoData) {
		const button = (
			<EvButton
				className="documentation-link"
				onClick={() => window.open(WEB_SDK_DOCUMENTATION_LINK, '_blank')}
			>
				Read Documentation
			</EvButton>
		);

		return (
			<WebSdkError
				mainTitle={WEB_SDK_NO_SYNCED_SESSIONS_TITLE}
				secondaryMsg={WEB_SDK_NO_SYNCED_SESSIONS_MSG}
				icon={CloudStatusIcon}
				button={button}
			/>
		);
	}

	return <WebSdkError icon={CloudStatusIcon} />;
};
