import keyBy from 'lodash-es/keyBy';
import { SeverityCountType } from 'src/apps/wfa/types/SeverityCountType';

import { EVINCED_ISSUE_SEVERITY_LIST } from '../../session-types/helpers/EvincedSeveritiesHelper';
import { WfaSessionSeveritiesQuery } from '../SessionSeverities.generated';

export const transformSessionSeveritiesResult = (
	rawResults: WfaSessionSeveritiesQuery
): SeverityCountType[] => {
	const severitiesCounts = rawResults?.wfa_session_type_runs;
	const severitiesMap = keyBy(severitiesCounts, 'severity_id_any');
	return EVINCED_ISSUE_SEVERITY_LIST.map(({ id, key, name }) => ({
		id: key,
		name: severitiesMap[id]?.severity_name_any ?? name,
		severity: severitiesMap[id]?.severity_name_any ?? name,
		count: severitiesMap[id]?.total_issues_sum ?? 0
	}));
};
