import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { EvSpinner } from '@evinced-private/ui-common';

import { handleSuccessfulAuthentication } from 'src/common/helpers/AuthenticationHelper';
import { useUniversalLogin } from 'src/common/hooks/useUniversalLogin';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { Logger } from 'src/common/services/Logger';
import { getRedirectToPath } from 'src/common/services/LoginRedirectionService';

export const AuthToken: FC = () => {
	const { getAccessToken } = useUniversalLogin();
	const history = useHistory();
	const { updateUserTenant } = useUserTenant();
	const handleAuthentication = useCallback(async () => {
		try {
			const accessToken = await getAccessToken();
			await handleSuccessfulAuthentication(
				{ accessToken, idToken: accessToken, appState: null },
				history
			);
			try {
				await updateUserTenant();
			} catch (err) {
				Logger.error(`Failed to update user and tenant with error: ${err}`);
			}
			const { path } = getRedirectToPath();
			history.push(path);
		} catch (e) {
			console.error(e);
		}
	}, [getAccessToken, history, updateUserTenant]);

	useEffect(() => {
		handleAuthentication();
	}, [handleAuthentication]);

	return <EvSpinner />;
};
