import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWebSdkTestBaseData } from 'src/apps/web-sdks/services/WebSdkDashboardService';
import { IWebSdkTestBaseData } from 'src/apps/web-sdks/types/WebSdkDashboardServiceTypes';
import { WEB_SDK_TEST_BASE_DATA } from 'src/common/providers/reactQueryProvider/QueryKeys';

export const useWebSdkTestBaseDataQuery = (runId: string): UseQueryResult<IWebSdkTestBaseData> => {
	const testBaseDataQuery = useQuery([WEB_SDK_TEST_BASE_DATA, runId], () =>
		getWebSdkTestBaseData(runId)
	);

	return testBaseDataQuery;
};
