import React, { FC } from 'react';

import { BUTTON_TYPES, EvConfirm } from '@evinced-private/ui-common';

import { PLATFORM_APP_ID } from 'src/common/consts/dom-consts';

interface IDeleteSessionConfirmationPopup {
	onConfirm: () => void;
}

export const DeleteSessionConfirmationPopup: FC<IDeleteSessionConfirmationPopup> = ({
	onConfirm
}: IDeleteSessionConfirmationPopup) => {
	const onConfirmDelete = async (closeModal: () => void): Promise<void> => {
		await onConfirm();
		closeModal();
	};

	return (
		<EvConfirm
			appElement={PLATFORM_APP_ID}
			title="Confirm Delete"
			approveButtonText="Delete Session"
			onConfirm={onConfirmDelete}
			promptMessage="Are you sure you want to delete this session?"
			triggerButtonProps={{
				type: BUTTON_TYPES.ACTION,
				title: 'Delete',
				children: 'Delete'
			}}
		/>
	);
};
