import React, { FC, useState } from 'react';

import classNames from 'classnames';

import { EvErrorCard, TITLE_MODES } from '@evinced-private/ui-common';

import { DASHBOARD_ERROR_MSG } from 'src/common/pages/dashboards-container/errorMessages';
import { DevelopmentToggles, getSingleToggle } from 'src/common/services/TogglesService';

import { PlatformPopup } from '../platform-popup/PlatformPopup';

import './DetailsPopup.scss';

interface IDetailsPopupProps {
	renderDetailsBlock: (setError: (msg: string) => void) => JSX.Element;
	issueType?: string;
	onClose?: () => void;
}

const enableAzureIntegration = getSingleToggle(DevelopmentToggles.ENABLE_AZURE_INTEGRATION);

export const DetailsPopup: FC<IDetailsPopupProps> = ({
	renderDetailsBlock,
	onClose,
	issueType = ''
}: IDetailsPopupProps) => {
	const [error, setError] = useState(null);
	const classes: string = classNames(error ? 'error-popup' : 'details-popup', {
		'azure-popup': enableAzureIntegration
	});

	return (
		<div>
			<PlatformPopup
				isOpen
				title={error ? 'Error' : issueType}
				className={classes}
				isBgTransparent
				isCentered={!!error}
				isControlled
				onClose={() => {
					setError?.(null);
					onClose?.();
				}}
				popupWidth={1050}
				titleMode={TITLE_MODES.MAIN}
			>
				{error ? (
					<EvErrorCard title="Error" contactUsMessage={DASHBOARD_ERROR_MSG} />
				) : (
					renderDetailsBlock(setError)
				)}
			</PlatformPopup>
		</div>
	);
};
