import React, { FC, useState } from 'react';

import classNames from 'classnames';

import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';

import DownChevronBlueIcon from 'src/common/components/icons/DownChevronBlueIcon.svg';
import UpChevronBlueIcon from 'src/common/components/icons/UpChevronBlueIcon.svg';

import './LabelsPanel.scss';

interface ILabelsPanel {
	labels: string[];
}

export const LabelsPanel: FC<ILabelsPanel> = ({ labels }) => {
	const [isLabelPanelOpen, setIsLabelPanelOpen] = useState<boolean>(false);
	const numberOfLablesInLine = 3;
	const renderTestLables = (labels: string[]): JSX.Element[] => {
		let labelsInLine = [];
		return labels.sort().map((label, index) => {
			const labelKeyValue = label.split(':');

			// create new line every numberOfLablesInLine labels (when remainder is 0)
			const newLine = index !== 0 && (index + 1) % numberOfLablesInLine === 0;

			// add label to be rendered in line
			labelsInLine.push(labelKeyValue);

			// if new line or last label - render labels line
			if (newLine || index === labels.length - 1) {
				const labelsLine = labelsInLine;
				labelsInLine = [];
				return (
					<li className="labels-line" key={label}>
						{labelsLine.map((value) => (
							<span className="test-label" key={`${label}-${value}-test-label`}>
								<span className="label-key">{`${value[0]} :`}</span>
								<span className="label-value">{value[1]}</span>
							</span>
						))}
					</li>
				);
			}
			return null;
		});
	};
	return (
		<div className="labels-panel">
			<ul className={classNames('test-labels', { open: isLabelPanelOpen })}>
				{renderTestLables(labels)}
			</ul>
			{labels.length > 9 && (
				<EvButton
					title={isLabelPanelOpen ? 'Collapse Labels Panel' : 'Expand Labels Panel'}
					type={BUTTON_TYPES.ICON}
					onClick={() => setIsLabelPanelOpen(!isLabelPanelOpen)}
					className="show-more-less-button"
				>
					{isLabelPanelOpen ? 'Show less' : 'Show more'}
					<EvIcon
						className="chevron-icon"
						icon={isLabelPanelOpen ? UpChevronBlueIcon : DownChevronBlueIcon}
					/>
				</EvButton>
			)}
		</div>
	);
};
