// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-sessions-table {
  margin-top: 30px;
}
.wfa-sessions-table .wfa-session-name .link-text {
  word-break: break-all;
  white-space: pre-wrap;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/apps/wfa/pages/sessions/sections/table/WfaSessionsTable.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AAEE;EACC,qBAAA;EACA,qBAAA;EACA,oBAAA;EACA,aAAA;EACA,qBAAA;EACA,4BAAA;AAAH","sourcesContent":[".wfa-sessions-table {\n\tmargin-top: 30px;\n\n\t.wfa-session-name {\n\t\t.link-text {\n\t\t\tword-break: break-all;\n\t\t\twhite-space: pre-wrap;\n\t\t\tdisplay: -webkit-box;\n\t\t\tline-clamp: 5;\n\t\t\t-webkit-line-clamp: 5;\n\t\t\t-webkit-box-orient: vertical;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
