import { IGetHTMLReportArgs, IGetMfaSessionsArgs } from 'src/apps/mobile-common/types/mfa-types';
import { apiCall } from 'src/common/services/api/ServerApi';

import { API_URL_PATHS } from '../../../common/consts/ApiUrlPaths';
import {
	IGetMSdkSessionsResponse,
	IMSdkEndpointResponse
} from '../../mobile-common/types/MobileSdkTypes';

// TODO: renanme args type to someting more generic
export const getMobileSdkSessions = async ({
	tenantId,
	productId
}: IGetMfaSessionsArgs): Promise<IGetMSdkSessionsResponse[]> => {
	const res: IGetMSdkSessionsResponse[] = await apiCall(
		'GET',
		`tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions`
	);
	return res;
};

// TODO: renanme args type to someting more generic
export const getMobileSdkSessionReport = async ({
	tenantId,
	productId,
	sessionId
}: IGetHTMLReportArgs): Promise<string> => {
	const res: IMSdkEndpointResponse = await apiCall(
		'GET',
		`/tenants/${tenantId}/${API_URL_PATHS.PRODUCTS_EXPORT_PATH}/${productId}/sessions/${sessionId}/html-report`
	);
	return res.link;
};
