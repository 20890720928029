import { API_URL_PATHS } from 'src/common/consts/ApiUrlPaths';
import { apiCall } from 'src/common/services/api/ServerApi';

import { Logger } from '../Logger';

const { PROPERTIES_URL_PATH } = API_URL_PATHS;

export const getPropertiesMap = async (): Promise<Map<string, string>> => {
	try {
		const properties: { name: string; id: string }[] = await apiCall(
			'GET',
			`${PROPERTIES_URL_PATH}`,
			{
				isAbsoluteUrl: true
			}
		);
		const resultMap = new Map(properties.map((property) => [property.id, property.name]));

		return resultMap;
	} catch (e) {
		Logger.error('PropertiesService -> getPropertiesMap failed with error', e);
		return new Map();
	}
};
