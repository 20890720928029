import { RoutesHelper } from '../helpers/RoutesHelper';
import { ITenant, ProductType } from '../interfaces/Tenant';
import { TGetToggle } from '../providers/configurationProvider/ConfigurationProvider';

import { getProductDashboardAccessPermission } from './CommonDashboardService';

export const getHomepageRedirectToLink = (tenant: ITenant, getToggle: TGetToggle): string => {
	const isPlatformEnabledForMfa = getProductDashboardAccessPermission(
		tenant,
		getToggle,
		ProductType.MOBILE_FLOW_ANALYZER
	);
	if (isPlatformEnabledForMfa) {
		return RoutesHelper.getMfaDashboardPath();
	}
	const isPlatformEnabledForMSdk = getProductDashboardAccessPermission(
		tenant,
		getToggle,
		ProductType.MOBILE_SDK
	);
	if (isPlatformEnabledForMSdk) {
		return RoutesHelper.getMobileSdkDashboardPath();
	}
	const isPlatformEnabledForWfa = getProductDashboardAccessPermission(
		tenant,
		getToggle,
		ProductType.WEB_FLOW_ANALYZER
	);
	if (isPlatformEnabledForWfa) {
		return RoutesHelper.getWfaDashboardPath();
	}
	return RoutesHelper.getWebSdkDashboardPath();
};
