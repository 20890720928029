import React, { FC } from 'react';

import { ProductType } from 'src/common/interfaces/Tenant';
import { DashboardContainer } from 'src/common/pages/dashboards-container/DashboardContainer';

import { WfaSessionsPage } from '../sessions/WfaSessionsPage';

export const DashboardContainerWFA: FC = () => (
	<DashboardContainer product={ProductType.WEB_FLOW_ANALYZER}>
		<WfaSessionsPage />
	</DashboardContainer>
);
