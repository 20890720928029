import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import { HtmlReport } from 'src/apps/mobile-common/pages/html-report/HtmlReport';
import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { hasLicenseForProduct } from 'src/common/services/CommonDashboardService';

import { NotFound404Page } from '../../../common/pages/not-found-404-page/NotFound404Page';
import { Unauthorized403Page } from '../../../common/pages/unauthorized-403-page/Unauthorized403Page';
import { getMsdkReportIdParam } from '../../../common/services/breadcrumbs/MsdkUrlService';
import { DashboardContainerMobileSDK } from '../pages/dashboard-container/DashboardContainerMobileSdk';

const productType = ProductType.MOBILE_FLOW_ANALYZER;

export const MobileSdkRouter: FC = () => {
	const { tenant } = useUserTenant();
	const hasLicense = hasLicenseForProduct(tenant, productType);

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getMobileSdkDashboardPath()}
					component={DashboardContainerMobileSDK}
				/>
				<Route
					exact
					path={RoutesHelper.getMobileSdkTestReportPathname()}
					render={({ location }) => {
						return hasLicense ? (
							<HtmlReport sessionId={getMsdkReportIdParam(location)} msdkReport />
						) : (
							<Unauthorized403Page />
						);
					}}
				/>
				<Route
					render={() => <NotFound404Page redirectTo={RoutesHelper.getMobileSdkDashboardPath()} />}
				/>
			</Switch>
		</Router>
	);
};
