import { SessionSummaryResult } from '../../../WfaDashboardServiceTypes';
import { WfaSessionSummaryQuery } from '../WfaSessionSummary.generated';

export const transformSessionSummaryResults = (
	rawResults: WfaSessionSummaryQuery
): SessionSummaryResult => {
	return {
		totalComponents: rawResults?.wfa_session_components_runs?.[0]?.component_id_count || 0,
		totalPages: rawResults?.wfa_session_runs?.[0]?.total_pages || 0,
		totalIssues: rawResults?.wfa_session_runs?.[0]?.total_issues || 0
	};
};
