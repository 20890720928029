import React, { FC } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import * as H from 'history';

import { AuthenticationChecker } from 'src/apps/main/components/authentication-checker/AuthenticationChecker';
import { AuthToken } from 'src/apps/main/pages/authentication/AuthToken';
import { LoginPage } from 'src/apps/main/pages/authentication/LoginPage';
import { SignupPage } from 'src/apps/main/pages/authentication/SignupPage';
import { AzureAuthenticationPage } from 'src/apps/main/pages/azure-authentication-page/AzureAuthenticationPage';
import { InvitationRedirectionPage } from 'src/apps/main/pages/invitation-redirection/InvitationRedirectionPage';
import { JiraAuthenticationPage } from 'src/apps/main/pages/jira-authentication-page/JiraAuthenticationPage';
import { NotSupportedScreenSizePage } from 'src/apps/main/pages/not-supported-screen-size/NotSupportedScreenSizePage';
import { MfaRouter } from 'src/apps/mfa/router/MfaRouter';
import { MobileSdkRouter } from 'src/apps/mobile-sdk/router/MobileSdkRouter';
import { WebSdkRouter } from 'src/apps/web-sdks/router/WebSdkRouter';
import { WfaRouter } from 'src/apps/wfa/router/WfaRouter';
import { PrivateRoute } from 'src/common/components/auth/PrivateRoute';
import { DashboardHeader } from 'src/common/components/dashboard-header/DashboardHeader';
import { MAIN_TITLE } from 'src/common/components/ev-main-title/EvMainTitle';
import { isDeviceSupported } from 'src/common/helpers/DeviceHelper';
import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { DashboardContainerHome } from 'src/common/pages/dashboards-container/products/DashboardContainerHome';
import { NotFound404Page } from 'src/common/pages/not-found-404-page/NotFound404Page';
import { Unauthorized403Page } from 'src/common/pages/unauthorized-403-page/Unauthorized403Page';
import { AppProvider } from 'src/common/providers/AppProvider';

import './App.scss';

type IApp = {
	history: H.History;
};

export const App: FC<IApp> = ({ history }) => {
	if (!isDeviceSupported()) {
		return <NotSupportedScreenSizePage />;
	}

	const isAuthenticationRequired = (pathname: string): boolean => {
		const unauthenticatedRoutes = [
			RoutesHelper.getSignupPath(),
			RoutesHelper.getConnectPath(),
			RoutesHelper.getLoginPath(),
			RoutesHelper.getJiraAuthenticationPath(),
			RoutesHelper.getAzureAuthenticationPath(),
			'/screenshots'
		];
		const isUnauthenticatedPage = unauthenticatedRoutes.some((path) => {
			return pathname.startsWith(path);
		});
		return !isUnauthenticatedPage;
	};

	return (
		<AppProvider>
			<Router history={history}>
				<div className="app new-app">
					<Route
						render={({ location }) => {
							return isAuthenticationRequired(location.pathname) ? <AuthenticationChecker /> : null;
						}}
					/>

					{/* Need to use a Route for the Header to have access
			to the path variables, otherwise useParams don't work.
			Note that the order of the items in the path list matters */}
					<Route
						path={[
							'/mfa/report/:sessionId',
							'/mobile-sdk/report/:testId',
							'/web-sdk/test/:runId/issue/:issueId',
							'/web-sdk/test/:runId',
							'/'
						]}
						render={(props) => {
							// don't show the header on unauthenticated screens
							const isAuthRequired = isAuthenticationRequired(props.location.pathname);
							document.title = 'Evinced Platform';

							if (isAuthRequired) {
								return <DashboardHeader isAuthenticatedPage />;
							}
							return null;
						}}
					/>
					<Route exact path={RoutesHelper.getConnectPath()} component={InvitationRedirectionPage} />
					<main id="main-section" className="main-section" aria-labelledby={MAIN_TITLE}>
						<Switch>
							{/* Signup related */}
							<Route exact path={RoutesHelper.getLoginPath()} render={() => <LoginPage />} />
							<Route exact path={RoutesHelper.getSignupPath()} render={() => <SignupPage />} />
							<Route exact path={RoutesHelper.getLoginSuccessPath()} render={() => <AuthToken />} />

							{/* JIRA Azure related */}
							<Route
								exact
								path={RoutesHelper.getJiraAuthenticationPath()}
								component={JiraAuthenticationPage}
							/>
							<Route
								exact
								path={RoutesHelper.getAzureAuthenticationPath()}
								component={AzureAuthenticationPage}
							/>

							{/* Inner apps */}
							<PrivateRoute
								exact
								path={RoutesHelper.getUnauthorized403Page()}
								component={Unauthorized403Page}
							/>
							<PrivateRoute path={RoutesHelper.getWfaDashboardPath()} component={WfaRouter} />
							<PrivateRoute path={RoutesHelper.getMfaDashboardPath()} component={MfaRouter} />
							<PrivateRoute
								path={RoutesHelper.getMobileSdkDashboardPath()}
								component={MobileSdkRouter}
							/>
							<PrivateRoute path={RoutesHelper.getWebSdkDashboardPath()} component={WebSdkRouter} />
							<PrivateRoute
								exact
								path={RoutesHelper.getHomepagePath()}
								component={DashboardContainerHome}
							/>
							<PrivateRoute
								exact
								path={RoutesHelper.getNotFound404Page()}
								component={NotFound404Page}
							/>
							{/* Error handling */}
							<Route component={NotFound404Page} />
						</Switch>
					</main>
				</div>
			</Router>
		</AppProvider>
	);
};
