import { PlatformIssueDetails } from 'src/common/types/IssueDetailsType';
import { Report } from 'src/common/types/ReportModel';

export const mapPlatformIssueDetailsToReport = (issue: PlatformIssueDetails): Report => ({
	...issue,
	pageUrl: issue.url,
	signature: '',
	severity: { id: issue.severity, name: issue.severity, newId: issue.severity },
	type: { id: issue.type, name: issue.type, newId: issue.type },
	pageScreenshot: issue.screenshotUrl
});
