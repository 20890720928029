import { upperFirst } from 'lodash-es';

import { OptionType } from '@evinced-private/ui-common';

import { SummaryItem } from 'src/common/interfaces/SummaryItem';
import { ProductType } from 'src/common/interfaces/Tenant';
import { Logger } from 'src/common/services/Logger';

import { DevelopmentToggles, getSingleToggle } from '../../services/TogglesService';
import { PlatformLabel } from '../../types/IssueDetailsType';
import { returnNonNull } from '../ArraysHelper';
import { formatShortMonthDatetime } from '../DateFormatHelper';

const enableWebSdkProperties = getSingleToggle(DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES);

/**
 * Receives the count for the last 'n' days and for the last '2n' days.
 *
 * Subtracts them to have the count for the last 'n' days and
 * the 'n' days before that.
 *
 * Then compares them to have growth percentage, also handling some edge cases
 * where division by 0 would occur.
 */
export const transformUsageDataResults = (
	lastPeriodCount: number,
	twoPeriodsCount: number,
	label: string,
	testPeriod?: OptionType
): SummaryItem => {
	const firstPeriodCount = twoPeriodsCount - lastPeriodCount;

	let value: number;
	let lift: number;

	if (firstPeriodCount !== 0) {
		value = lastPeriodCount;
		lift = ((lastPeriodCount - firstPeriodCount) / firstPeriodCount) * 100;
	} else {
		value = lastPeriodCount;
		lift = undefined;
	}

	if (!testPeriod || testPeriod.value === 'all') {
		lift = undefined;
	}

	return {
		value,
		lift,
		label
	};
};

export const normalizeCustomField = (label: (string | null)[]): [string, string] | null => {
	if (label.length === 2 && label[0] && label[1]) {
		return [upperFirst(label[0]), upperFirst(label[1])];
	}
	return null;
};

export const convertCustomFieldToFilterOption = (customField: [string, string]): OptionType => ({
	label: customField.join(': '),
	value: customField.join(': ')
});

const convertCustomFieldsToWebSdkLabel = (customField: [string, string]): PlatformLabel => ({
	name: customField[0],
	value: customField[1],
	isAutomatic: false
});

interface BuildExtendedLabelsParams {
	product: ProductType;
	flow?: string;
	environment?: string;
	runTime?: string;
	properties?: string[];
	customFields?: (string | null)[][];
	errorMessage?: string;
}

export const buildExtendedLabels = ({
	product,
	flow,
	environment,
	runTime,
	properties,
	customFields,
	errorMessage
}: BuildExtendedLabelsParams): PlatformLabel[] => {
	const missingDataErrors: string[] = [];
	const runTimeLabelDisplayString = product === ProductType.WEB_SDK ? 'Test date' : 'Session date';
	const runTimeLabel = runTime
		? [
				{
					name: runTimeLabelDisplayString,
					value: formatShortMonthDatetime(runTime),
					isAutomatic: true
				}
			]
		: [];

	if (!runTimeLabel.length) {
		missingDataErrors.push('runTime');
	}

	const environmentLabel = environment?.length
		? [{ name: 'Environment', value: upperFirst(environment), isAutomatic: true }]
		: [];

	if (!environmentLabel.length) {
		missingDataErrors.push('environment');
	}

	// put properties here as a placeholder to then populate values
	const propertiesLabel =
		properties?.length && enableWebSdkProperties
			? [{ name: 'Properties', value: properties.toString(), isAutomatic: true }]
			: [];

	const flowLabel = flow?.length
		? [{ name: 'Flow', value: upperFirst(flow), isAutomatic: true }]
		: [];

	if (errorMessage && missingDataErrors.length) {
		Logger.error(errorMessage, `Missing ${missingDataErrors.join(', ')}`);
	}

	const customLabels: PlatformLabel[] =
		customFields
			?.map(normalizeCustomField)
			?.filter(returnNonNull)
			?.map(convertCustomFieldsToWebSdkLabel) ?? [];

	return [...runTimeLabel, ...environmentLabel, ...propertiesLabel, ...flowLabel, ...customLabels];
};
