// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenshot-cell .image-container {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.screenshot-cell .image-container .screenshot-cell-img {
  height: 100px;
  width: 150px;
  object-fit: cover;
  border: 1px solid #8392a6;
}
.screenshot-cell .image-container .preview-not-available svg {
  height: 42px;
  width: 153px;
}`, "",{"version":3,"sources":["webpack://./src/common/components/screenshot-cell-formatter/ScreenshotCellFormatter.scss"],"names":[],"mappings":"AAGC;EACC,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAIE;EACC,aAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;AAFH;AAKG;EACC,YAAA;EACA,YAAA;AAHJ","sourcesContent":["@import '../../../styles/variables';\n\n.screenshot-cell {\n\t.image-container {\n\t\theight: 100px;\n\t\twidth: 100%;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\n\t\t.screenshot-cell-img {\n\t\t\theight: 100px;\n\t\t\twidth: 150px;\n\t\t\tobject-fit: cover;\n\t\t\tborder: 1px solid $input-border-color;\n\t\t}\n\t\t.preview-not-available {\n\t\t\tsvg {\n\t\t\t\theight: 42px;\n\t\t\t\twidth: 153px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
