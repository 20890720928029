import React, { FC } from 'react';

import { useWfaComponentSampleIssueQuery } from 'src/apps/wfa/hooks/useWfaComponentSampleIssueQuery';

import { ComponentDetailsBlock } from './component-details-block/ComponentDetailsBlock';

interface IWfaComponentDetailsBlockProps {
	runId: string;
	componentId: string;
	totalIssues: number;
	totalPages: number;
}

export const WfaComponentDetailsBlock: FC<IWfaComponentDetailsBlockProps> = ({
	runId,
	componentId,
	totalIssues,
	totalPages
}) => {
	const sampleIssueQuery = useWfaComponentSampleIssueQuery({ sessionId: runId, componentId });

	return (
		<ComponentDetailsBlock
			isLoading={sampleIssueQuery.isLoading}
			sampleIssue={sampleIssueQuery.data}
			totalIssues={totalIssues}
			totalPages={totalPages}
		/>
	);
};
