// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfa-dashboard-access-denied-msg {
  white-space: pre-wrap;
}
.mfa-dashboard-access-denied-msg .ev-popup .ev-button-container {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/common/pages/dashboards-container/DashboardContainer.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;AACD;AAEE;EACC,gBAAA;AAAH","sourcesContent":[".mfa-dashboard-access-denied-msg {\n\twhite-space: pre-wrap;\n\n\t.ev-popup {\n\t\t.ev-button-container {\n\t\t\tmargin-top: 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
