import { OptionType, TypesCountBySeverity } from '@evinced-private/ui-common';

import { IComponentSampleIssue } from 'src/apps/wfa/types/ComponentSampleIssue';
import { SeverityCountType } from 'src/apps/wfa/types/SeverityCountType';
import { IWfaSessionsFilterOption } from 'src/common/helpers/DataFilterHelper';
import { buildRunIdParam } from 'src/common/helpers/GQLRequestsHelper/buildRunIdParam';
import { SummaryItem } from 'src/common/interfaces/SummaryItem';
import { gqlClient } from 'src/common/services/api/GraphQLRequestApi';
import { Logger } from 'src/common/services/Logger';
import { ITableQueryParams } from 'src/common/types/TableQueryTypes';

import { buildWfaComponentSampleIssueQueryVariables } from './queries/component-sample-issue/helpers/buildVariables';
import { transformWfaComponentSampleIssueResults } from './queries/component-sample-issue/helpers/transformResults';
import { WfaComponentSampleIssueQuery } from './queries/component-sample-issue/WfaComponentSampleIssue.generated';
import { WfaComponentSampleIssue } from './queries/component-sample-issue/WfaComponentSampleIssue.gql';
import { buildSessionsComponentsQueryVariables } from './queries/components/helpers/buildVariables';
import { transformSessionComponentsResults } from './queries/components/helpers/transformResults';
import { WfaComponentsQuery } from './queries/components/WfaComponents.generated';
import { WfaComponents } from './queries/components/WfaComponents.gql';
import { transformSessionDetailsResult } from './queries/session-details/helpers/transformResults';
import { WfaSessionDetailsQuery } from './queries/session-details/WfaSessionDetails.generated';
import { WfaSessionDetails } from './queries/session-details/WfaSessionDetails.gql';
import { buildSessionIssuesVariables } from './queries/session-issues/helpers/buildVariables';
import { transformSessionIssuesResults } from './queries/session-issues/helpers/transformResults';
import { WfaSessionIssuesQuery } from './queries/session-issues/WfaSessionIssues.generated';
import { WfaSessionIssues } from './queries/session-issues/WfaSessionIssues.gql';
import { transformWfaSessionIssuesFilterOptionsResults } from './queries/session-issues-filter-options/helpers/transformResults';
import { WfaSessionIssuesFilterOptionsQuery } from './queries/session-issues-filter-options/WfaSessionIssuesFilterOptions.generated';
import { WfaSessionIssuesFilterOptions } from './queries/session-issues-filter-options/WfaSessionIssuesFilterOptions.gql';
import { transformWfaSessionNameResults } from './queries/session-name/helpers/transformResults';
import { WfaSessionNameQuery } from './queries/session-name/WfaSessionName.generated';
import { WfaSessionName } from './queries/session-name/WfaSessionName.gql';
import { transformSessionSeveritiesResult } from './queries/session-severities/helpers/transformResults';
import { WfaSessionSeveritiesQuery } from './queries/session-severities/SessionSeverities.generated';
import { WfaSessionSeverities } from './queries/session-severities/SessionSeverities.gql';
import { transformSessionSummaryResults } from './queries/session-summary/helpers/transformResults';
import { WfaSessionSummary } from './queries/session-summary/WfaSessionSummary.gql';
import { transformSessionIssueTypesBreakdownResult } from './queries/session-types/helpers/transformResults';
import { SessionIssueTypesQuery } from './queries/session-types/SessionIssueTypes.generated';
import { SessionIssueTypes } from './queries/session-types/SessionIssueTypes.gql';
import { buildSessionsQueryVariables } from './queries/sessions/helpers/buildVariables';
import { transformSessionsResults } from './queries/sessions/helpers/transformResults';
import { WfaSessionsQuery } from './queries/sessions/WfaSessions.generated';
import { WfaSessions } from './queries/sessions/WfaSessions.gql';
import { transformTopComponentsResult } from './queries/top-components/helpers/transformResults';
import { TopSessionComponentsQuery } from './queries/top-components/TopSessionComponents.generated';
import { TopSessionComponents } from './queries/top-components/TopSessionComponents.gql';
import { buildWfaUsageDataVariables } from './queries/usage-data/helpers/buildVariables';
import { transformWfaUsageDataResults } from './queries/usage-data/helpers/transformResults';
import { WfaUsageDataQuery } from './queries/usage-data/WfaUsageData.generated';
import { WfaUsageData } from './queries/usage-data/WfaUsageData.gql';
import { transformUserOptionsResults } from './queries/user-options/helpers/transformResults';
import { WfaUserOptionsQuery } from './queries/user-options/WfaUserOptions.generated';
import { WfaUserOptions } from './queries/user-options/WfaUserOptions.gql';
import {
	IGeWfaSessionAllIssuesParams,
	IGeWfaSessionComponentsParams,
	IWfaSessionDetails,
	SessionSummaryResult,
	TWfaSessionAllIssuesResult,
	TWfaSessionComponentsResult,
	TWfaSessionIssuesFilterOptions,
	TWfaSessionsResult,
	TWfaSessionTopComponentsResult
} from './WfaDashboardServiceTypes';

export const getWfaUsageData = async (
	filters: IWfaSessionsFilterOption
): Promise<SummaryItem[]> => {
	try {
		const variables = buildWfaUsageDataVariables(filters);
		const result = await gqlClient.request<WfaUsageDataQuery>(WfaUsageData, variables);

		return transformWfaUsageDataResults(result, filters.sessionsPeriod?.[0]);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaUsageData failed with an error:', e);
		throw e;
	}
};

export const getWfaSessions = async ({
	...restParams
}: ITableQueryParams): Promise<TWfaSessionsResult> => {
	try {
		const variables = buildSessionsQueryVariables(restParams);
		const result = await gqlClient.request<WfaSessionsQuery>(WfaSessions, variables);
		return transformSessionsResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessions failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionDetails = async (sessionId: string): Promise<IWfaSessionDetails> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<WfaSessionDetailsQuery>(WfaSessionDetails, variables);
		return transformSessionDetailsResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionDetails failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionName = async (sessionId: string): Promise<string> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<WfaSessionNameQuery>(WfaSessionName, variables);

		return transformWfaSessionNameResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionName failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionAllIssues = async (
	params: IGeWfaSessionAllIssuesParams
): Promise<TWfaSessionAllIssuesResult> => {
	try {
		const variables = buildSessionIssuesVariables(params);
		const result = await gqlClient.request<WfaSessionIssuesQuery>(WfaSessionIssues, variables);
		return transformSessionIssuesResults(result);
	} catch (e) {
		Logger.error('getWfaSessionAllIssues -> getWfaSessionDetails failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionComponents = async (
	params: IGeWfaSessionComponentsParams
): Promise<TWfaSessionComponentsResult> => {
	try {
		const variables = buildSessionsComponentsQueryVariables(params);
		const result = await gqlClient.request<WfaComponentsQuery>(WfaComponents, variables);
		return transformSessionComponentsResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionComponents failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionTopComponents = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<TWfaSessionTopComponentsResult> => {
	try {
		const variables = buildRunIdParam(sessionId);

		const result = await gqlClient.request<TopSessionComponentsQuery>(
			TopSessionComponents,
			variables
		);

		return transformTopComponentsResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionSeverities = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<SeverityCountType[]> => {
	try {
		const variables = buildRunIdParam(sessionId);

		const result = await gqlClient.request<WfaSessionSeveritiesQuery>(
			WfaSessionSeverities,
			variables
		);

		return transformSessionSeveritiesResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionIssueTypes = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<TypesCountBySeverity[]> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<SessionIssueTypesQuery>(SessionIssueTypes, variables);
		return transformSessionIssueTypesBreakdownResult(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaSessionTopComponents failed with an error:', e);
		throw e;
	}
};

export const getSessionSummary = async ({
	sessionId
}: {
	sessionId: string;
}): Promise<SessionSummaryResult> => {
	try {
		const variables = buildRunIdParam(sessionId);
		const result = await gqlClient.request<SessionIssueTypesQuery>(WfaSessionSummary, variables);
		return transformSessionSummaryResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getSessionSummary failed with an error:', e);
		throw e;
	}
};

export const getWfaSessionIssuesFilterOptions = async (
	runId: string
): Promise<TWfaSessionIssuesFilterOptions> => {
	try {
		const variables = buildRunIdParam(runId);

		const result = await gqlClient.request<WfaSessionIssuesFilterOptionsQuery>(
			WfaSessionIssuesFilterOptions,
			variables
		);

		return transformWfaSessionIssuesFilterOptionsResults(result);
	} catch (e) {
		Logger.error(
			'WfaDashboardService -> getWfaSessionIssuesFilterOptions failed with an error:',
			e
		);
		throw e;
	}
};

export const getWfaComponentSampleIssue = async (
	sessionId: string,
	componentId: string
): Promise<IComponentSampleIssue> => {
	try {
		const variables = buildWfaComponentSampleIssueQueryVariables(sessionId, componentId);

		const result = await gqlClient.request<WfaComponentSampleIssueQuery>(
			WfaComponentSampleIssue,
			variables
		);

		const transformedResult = transformWfaComponentSampleIssueResults(componentId, result);
		if (!transformedResult) {
			throw new Error(
				`Sample issue for component ${componentId} in session ${sessionId} not found.`
			);
		}

		return transformedResult;
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaComponentSampleIssue failed with an error:', e);
		throw e;
	}
};

export const getWfaUserOptions = async (): Promise<OptionType[]> => {
	try {
		const result = await gqlClient.request<WfaUserOptionsQuery>(WfaUserOptions);

		return transformUserOptionsResults(result);
	} catch (e) {
		Logger.error('WfaDashboardService -> getWfaUserOptions failed with an error:', e);
		throw e;
	}
};
