/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { EvSpinner } from '@evinced-private/ui-common';

import { getInvitationTokenFromUrl } from 'src/common/helpers/InvitationTokenHelper';

import { AuthToken } from './AuthToken';

const invitationToken = getInvitationTokenFromUrl();

export const LoginPage = withAuthenticationRequired(AuthToken, {
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		...(invitationToken ? { appState: { invitationToken } } : {})
	}
});
