import { useMemo } from 'react';

import { usePermissions } from 'src/common/hooks/usePermissions';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useBreadcrumbRoutes } from 'src/common/providers/breadcrumbsProvider/BreadcrumbsProvider';

import { getWfaBreadcrumbRoutes } from '../helpers/WfaDashboardHelpers';

export const useWfaSessionBreadcrumbs = (sessionId: string, sessionName: string): void => {
	const breadcrumbRoutes = useMemo(
		() => getWfaBreadcrumbRoutes(sessionId, sessionName),
		[sessionId, sessionName]
	);

	const isPlatformEnabledForWfa = usePermissions(ProductType.WEB_FLOW_ANALYZER);
	// do not display breadcrumbs if the user has no access to the dashboard
	useBreadcrumbRoutes(isPlatformEnabledForWfa ? breadcrumbRoutes : []);
};
