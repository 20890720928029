import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WEB_SDK_TEST_DETAILS } from 'src/common/providers/reactQueryProvider/QueryKeys';

import { getWebSdkTestDetails } from '../services/WebSdkDashboardService';
import { IWebSdkTestDetails } from '../types/WebSdkDashboardServiceTypes';

export const useWebSdkTestDetailsQuery = (runId: string): UseQueryResult<IWebSdkTestDetails> =>
	useQuery(
		[WEB_SDK_TEST_DETAILS, runId],
		async (): Promise<IWebSdkTestDetails> => getWebSdkTestDetails(runId)
	);
