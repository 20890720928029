import { sum, sumBy } from 'lodash-es';

import { EvRaceBarDatum } from '@evinced-private/ui-common';

import { SessionComponentType } from 'src/apps/wfa/types/SessionComponentType';

export const prepareDataForChart = (
	topComponents: SessionComponentType[]
): { barData: EvRaceBarDatum[]; totalPercentageCovered?: number } => {
	if (topComponents.length === 0) {
		return { barData: [], totalPercentageCovered: 0 };
	}

	const totalPercentageTopComponents = sum(topComponents.map((c) => c.percentage));
	const barData: EvRaceBarDatum[] = topComponents.map((component) => {
		const id = component.componentId;
		const { percentage } = component;
		const relativePercentageInChart = Math.round((percentage * 100) / totalPercentageTopComponents);

		return {
			id,
			percent: percentage,
			url: 'tbd',
			widthPercentageOnChart: relativePercentageInChart
		};
	});
	const totalIssueCovered = sumBy(barData, 'percent');
	const percentageOfExcess = totalIssueCovered > 100 ? totalIssueCovered % 100 : 0;
	const updatedTotalIssueCovered =
		totalIssueCovered > 100 ? 100 : totalIssueCovered - percentageOfExcess;
	const updatedBarData = barData.map((barItemData) => {
		const { id, percent } = barItemData;
		return {
			...barItemData,
			percent,
			ariaLabel: `Component ID '${id}' generates : ${percent}% of the issues. See component's issue`
		};
	});

	return {
		barData: updatedBarData,
		totalPercentageCovered: updatedTotalIssueCovered
	};
};
