import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { SessionViews } from 'src/common/types/WfaSessionViews';

import { DashboardContainerWFA } from '../pages/dashboard-container/DashboardContainerWFA';
import { WfaSingleIssuePage } from '../pages/single-issue/WfaSingleIssuePage';
import { WfaSingleSessionPage } from '../pages/single-session/WfaSingleSessionPage';

const SESSION_ID_KEY = ':sessionId';
const ISSUE_ID_KEY = ':issueId';

export const WfaRouter: FC = () => {
	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.OVERVIEW)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.COMPONENTS)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.COMPONENTS} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.ALL_ISSUES)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.ALL_ISSUES} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaIssueInfoPath({
						runId: SESSION_ID_KEY,
						issueId: ISSUE_ID_KEY
					})}
					render={() => <WfaSingleIssuePage />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaSessionRootPath(SESSION_ID_KEY)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route exact path={RoutesHelper.getWfaDashboardPath()} component={DashboardContainerWFA} />
				<Route render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />} />
			</Switch>
		</Router>
	);
};
