import { NotGroupedComponent } from 'src/common/consts/NotGroupedComponent';
import { returnNonNull } from 'src/common/helpers/ArraysHelper';

import {
	IWebSdkTestIssue,
	TWebSdkTestIssuesResult
} from '../../../../types/WebSdkDashboardServiceTypes';
import { IssuesQuery } from '../Issues.generated';

export const transformIssuesResults = (rawResults: IssuesQuery): TWebSdkTestIssuesResult => {
	const rows: IWebSdkTestIssue[] =
		rawResults.issues?.filter(returnNonNull).map(
			(issue): IWebSdkTestIssue => ({
				id: issue.issue_id,
				type: issue.type_name,
				severity: issue.severity_name,
				component:
					issue.elements__component_id[0] === NotGroupedComponent.ID
						? NotGroupedComponent.DISPLAY_NAME
						: issue.elements__component_id[0],
				url: issue.url,
				pageTitle: issue.page_title,
				screenshotUrl: issue.screenshot,
				boundingBoxes: issue.elements__bounding_box_left.map((x, index) => ({
					x,
					y: issue.elements__bounding_box_top[index],
					width: issue.elements__bounding_box_width[index],
					height: issue.elements__bounding_box_height[index]
				}))
			})
		) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.issue_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.issue_id_count ?? 0;
	}
	return {
		rows,
		total,
		unfilteredTotal
	};
};
