import {
	IFilterDefinition,
	ISelectFilterDefinition,
	ITextFilterDefinition
} from '@evinced-private/ui-common';

import { FilterTypesNames } from 'src/common/consts/app_consts';

const searchFilter: ITextFilterDefinition = {
	id: 'searchValue',
	label: 'Search',
	filterType: 'text',
	defaultValue: '',
	componentProps: {
		placeholder: 'Search'
	}
};

const osFilter: ISelectFilterDefinition = {
	id: FilterTypesNames.OS,
	label: 'os',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select OS',
		allSelectedPlaceholder: 'All OS',
		someItemsSelectedText: (numberOfItems) => `OS (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'labels-filter-dropdown'
	}
};

const appFilter: ISelectFilterDefinition = {
	id: FilterTypesNames.APPS,
	label: 'apps',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select App',
		allSelectedPlaceholder: 'All Apps',
		someItemsSelectedText: (numberOfItems) => `Apps (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'labels-filter-dropdown'
	}
};

const versionFilter: ISelectFilterDefinition = {
	id: FilterTypesNames.VERSIONS,
	label: 'versions',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Version',
		allSelectedPlaceholder: 'All Versions',
		someItemsSelectedText: (numberOfItems) => `Versions (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'labels-filter-dropdown'
	}
};

export const MfaSessionsPageFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	osFilter,
	appFilter,
	versionFilter
];
