import { OptionType } from '@evinced-private/ui-common';

import { ConformanceLevels } from 'src/common/consts/ConformanceLevels';
import { NotGroupedComponent } from 'src/common/consts/NotGroupedComponent';

import { EvincedTypeNames } from '../consts/EvincedTypeNames';

function formatTypeLabel(option, isToUppercase = true): string {
	if (option in EvincedTypeNames) {
		return EvincedTypeNames[option];
	}
	if (option === NotGroupedComponent.ID) {
		return NotGroupedComponent.DISPLAY_NAME;
	}
	if (option in ConformanceLevels) {
		return ConformanceLevels[option];
	}
	if (isToUppercase) {
		return capitalizeFirstLetter(option);
	}
	return option;
}

export function formatFilterOptionsForEvSelect(
	filterOptions: string[],
	labelPrefix = '',
	isToUppercase?: boolean
): OptionType[] {
	return filterOptions.map((option) => {
		return {
			value: option,
			label: labelPrefix + formatTypeLabel(option, isToUppercase)
		};
	});
}

export const capitalizeFirstLetter = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addPercentSign = (v: string): string => `${v}%`;

// general formatter. taken from https://stackoverflow.com/a/9462382
export const nFormatter = (num, digits = 1): string => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

	let item;
	if (num <= 1) {
		item = lookup[0];
	} else {
		item = lookup
			.slice()
			.reverse()
			.find((item) => num >= item.value);
	}

	return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};
