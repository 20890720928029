import React, { FC } from 'react';

import { DashboardContainer } from 'src/common/pages/dashboards-container/DashboardContainer';

import { ProductType } from '../../../../common/interfaces/Tenant';
import { WebSdkTestsPage } from '../tests/WebSdkTestsPage';

export const DashboardContainerWebSDK: FC = () => (
	<DashboardContainer product={ProductType.WEB_SDK}>
		<WebSdkTestsPage />
	</DashboardContainer>
);
