import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaSessionName } from 'src/apps/wfa/services/WfaDashboardService';
import { WFA_SESSION_NAME } from 'src/common/providers/reactQueryProvider/QueryKeys';

export const useWfaSessionNameQuery = (sessionId: string): UseQueryResult<string> => {
	const sessionNameQuery = useQuery([WFA_SESSION_NAME, sessionId], () =>
		getWfaSessionName(sessionId)
	);

	return sessionNameQuery;
};
