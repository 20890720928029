// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenshot-block .screenshot-picture .screenshot-img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/common/components/screenshot-block/ScreenshotBlock.scss"],"names":[],"mappings":"AAEE;EACC,WAAA;AADH","sourcesContent":[".screenshot-block {\n\t.screenshot-picture {\n\t\t.screenshot-img {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
